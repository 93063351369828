import styled from 'styled-components'

import colors from '../../styles/colors'

const { primary } = colors

export const CalendarContent = styled.div`
  .ant-picker-calendar-mode-switch {
    display: none;
  }

  .ant-picker-calendar-date-content {
    height: 150px !important;

    //scrollbar
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${primary._000};
    }

    ::-webkit-scrollbar-thumb:hover {
      background: ${primary._100};
    }
  }

  //event-bullet parent
  .event-bullet {
  }
`
