import React, { useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import { useKeycloak } from '@react-keycloak/web'

const Homepage = () => {
  const { keycloak } = useKeycloak()

  useEffect(() => {
    // Redirect to login directly, remove to create home page
    if (!keycloak.authenticated) keycloak.login()
  }, [keycloak])

  if (keycloak?.authenticated) return <Navigate to={'/portal/dashboard'} />

  return <div></div>
}

export default Homepage
