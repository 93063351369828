import { Card, Result } from 'antd'
import React from 'react'

const StatusError = ({ code = 404 }) => {
  if (code === 404) {
    return (
      <Card>
        <Result
          status="404"
          title="404"
          subTitle={`A página que você está procurando não existe.`}
        />
      </Card>
    )
  }

  if (code === 403) {
    return (
      <Card>
        <Result
          status="403"
          title="403"
          subTitle={`Você não tem permissão para acessar esta página. Contate o administrador do sistema para obter mais informações.`}
        />
      </Card>
    )
  }

  return (
    <Card>
      <Result
        status="500"
        title="500"
        subTitle={`Ocorreu um erro interno no servidor.`}
      />
    </Card>
  )
}

export default StatusError
