import { useQuery } from '@tanstack/react-query'
import { Card, Col, Popover, Row, Typography, Table as AntTable } from 'antd'
import React, { useState } from 'react'
import Table from '~/components/Table'
import type { TableProps } from 'antd/es/table'
import api from '~/services/axios'
import { MaskMoney } from '~/helpers/Masks'
import { InlineAvatar } from '~/components/InlineAvatar'
import { SorterResult } from 'antd/es/table/interface'

type TablePaymentsByMonthProps = {
  year: number
}

export default function TablePaymentsByMonth({
  year,
}: Readonly<TablePaymentsByMonthProps>) {
  const [page, setPage] = useState([1, 10])
  const [sorter, setSorter] = useState<string | undefined>(undefined)

  const { Title, Text } = Typography

  const { isLoading: isLoadingPaymentsByMonth, data: paymentsByMonth } =
    useQuery(
      ['paymentsByMonth', page, year, sorter],
      () => {
        return api
          .get('/api/statistics/students/by-month', {
            params: {
              page: page[0],
              pageSize: page[1],
              year,
              sort: sorter,
            },
          })
          .then((response) => response.data)
          .catch((err) => {})
      },
      {
        cacheTime: 0,
      }
    )

  const onChange: TableProps<'columns'>['onChange'] = (
    pagination,
    filters,
    sorter,
    extra
  ) => {
    if ((sorter as SorterResult<'columns'>)?.field === 'user') {
      console.log(sorter)
      setSorter((sorter as SorterResult<'columns'>).order ?? undefined)
    }
    if (extra?.action === 'paginate') {
      setPage([pagination.current ?? 1, pagination.pageSize ?? 10])
    }

    if (extra?.action === 'filter') {
      setPage([1, pagination.pageSize ?? 10])
    }
  }

  const columns = [
    {
      title: 'Aluno',
      dataIndex: 'user',
      key: 'user',
      sorter: true,
      sortOrder: sorter,
      render: (_, item) => (
        <a href={`/portal/students/${item.id}?view=statement`}>
          <InlineAvatar name={item?.user?.name} src={item?.user?.picture} />
          {item?.user?.name}
        </a>
      ),
    },
    ...(paymentsByMonth?.data[0]?.open_payments?.map((month, index) => {
      return {
        title: month.date,
        dataIndex: ['open_payments', index],
        key: `open_payments`,
        render: (item, records) => {
          console.log('item', item, records)
          return item?.total === 0 &&
            records?.paid_payments[index]?.total === 0 ? (
            <Text type="secondary">{MaskMoney(0)}</Text>
          ) : (
            <Popover
              content={
                <div>
                  <p>Qtd pago: {records?.paid_payments[index]?.count}</p>
                  <p>Qtd em aberto: {item?.count}</p>

                  <p>
                    Valor pago:{' '}
                    {MaskMoney(records?.paid_payments[index]?.amount)}
                  </p>
                  <p>Valor em aberto: {MaskMoney(item?.amount)}</p>

                  <p>
                    Descontos concedidos:{' '}
                    {MaskMoney(records?.discount + records?.allowance)}
                  </p>
                  <p>
                    Descontos proposto pagamentos em aberto:{' '}
                    {MaskMoney(item?.discount + item?.allowance)}
                  </p>

                  <p>
                    Total pago:{' '}
                    {MaskMoney(records?.paid_payments[index]?.total)}
                  </p>
                  <p>Total em aberto: {MaskMoney(item?.total)}</p>
                </div>
              }
              title="Pagamentos"
            >
              <>
                {records?.paid_payments[index]?.total > 0 && (
                  <Text type="success">
                    {MaskMoney(records?.paid_payments[index]?.total)}
                  </Text>
                )}
                {records?.paid_payments[index]?.total > 0 && item.total > 0 && (
                  <> / </>
                )}
                {item?.total > 0 && (
                  <Text type="danger">{MaskMoney(item?.total)}</Text>
                )}
              </>
            </Popover>
          )
        },
      }
    }) || []),
  ]

  return (
    <Col sm={24} md={24} xl={24}>
      <Card
        loading={isLoadingPaymentsByMonth}
        bordered={false}
        style={{ height: '100%' }}
      >
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} xl={18}>
            <Title level={5}>Pagamentos alunos</Title>
          </Col>
        </Row>

        <Table
          size="small"
          dataSource={paymentsByMonth?.data || []}
          loading={isLoadingPaymentsByMonth}
          summary={(pageData) => {
            // total for every month
            // init object with 12 months
            const total: { total_open: number; total_paid: number }[] =
              Array.from({ length: 12 }, () => ({
                total_open: 0,
                total_paid: 0,
              }))

            pageData.forEach((item) => {
              item.open_payments.forEach((month, index) => {
                total[index].total_open = total[index].total_open + month.total
              })
            })

            pageData.forEach((item) => {
              item.paid_payments.forEach((month, index) => {
                total[index].total_paid = total[index].total_paid + month.total
              })
            })

            return (
              <AntTable.Summary.Row>
                <AntTable.Summary.Cell index={0}>Total</AntTable.Summary.Cell>
                {total.map((item, index) => (
                  <AntTable.Summary.Cell key={index} index={index}>
                    {item?.total_paid > 0 && (
                      <Text type="success">{MaskMoney(item?.total_paid)}</Text>
                    )}
                    {item?.total_paid > 0 && item?.total_open > 0 && <> / </>}
                    {item?.total_open > 0 && (
                      <Text type="danger">{MaskMoney(item?.total_open)}</Text>
                    )}
                    {item?.total_paid === 0 && item?.total_open === 0 && (
                      <Text type="secondary">{MaskMoney(0)}</Text>
                    )}
                  </AntTable.Summary.Cell>
                ))}
              </AntTable.Summary.Row>
            )
          }}
          rowKey={'id'}
          columns={columns}
          onChange={onChange}
          sticky={{
            offsetHeader: 55,
            offsetScroll: 0,
          }}
          pagination={{
            pageSize: paymentsByMonth?.per_page || 10,
            total: paymentsByMonth?.total || 0,
            current: paymentsByMonth?.current_page || 1,
            pageSizeOptions: ['10', '20', '50', '100', '150', '200'],
            showSizeChanger: true,
          }}
        />
      </Card>
    </Col>
  )
}
