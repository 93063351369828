import { Badge, Card, Col, Row, Space, Tooltip, Typography } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import colors from '~/styles/colors'
import { DashContainer } from '~/components/Next5DaysCalendar/styles'
import { useQuery } from '@tanstack/react-query'
import api from '~/services/axios'
import { InlineAvatar } from '~/components/InlineAvatar'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'
import useThemeStore from '~/stores/useThemeStore'

const Next5DaysCalendar = ({ id, as, currentDate }) => {
  const navigate = useNavigate()
  const { theme } = useThemeStore()
  // create arrays with next 5 days
  const days = []
  const daysOfWeek = []

  const { Text } = Typography
  const { gray } = colors

  for (let i = 0; i < 5; i++) {
    const day = dayjs(currentDate).add(i, 'day')
    days.push(day)

    const dayOfWeek = Intl.DateTimeFormat('pt-BR', {
      weekday: 'long',
    }).format(day.toDate())

    // capitalize first letter
    const dayOfWeekCapitalized =
      dayOfWeek.charAt(0).toUpperCase() + dayOfWeek.slice(1)

    daysOfWeek.push(dayOfWeekCapitalized)
  }

  const { data: events, isLoading: isLoadingEvents } = useQuery(
    [
      'events',
      id,
      as,
      days[0].format('YYYY-MM-DD'),
      days[4].format('YYYY-MM-DD'),
      dayjs(days[0]).set('date', 1).format('YYYY-MM-DD'),
    ],
    () =>
      api
        .get(as === 'office' ? `/api/events` : `/api/${as}/${id}/events`, {
          params:
            as === 'office'
              ? {
                  date: dayjs(days[0]).set('date', 1).format('YYYY-MM-DD'),
                }
              : {
                  start: days[0].format('YYYY-MM-DD'),
                  end: days[4].format('YYYY-MM-DD'),
                  datailed: true,
                },
        })
        .then((res) => {
          const data = res.data.filter(
            (event) => event?.status?.name !== 'Cancelado',
          )

          return data
        }),
  )

  return (
    <DashContainer>
      <Row gutter={0} wrap={false} style={{ maxHeight: 500, overflow: 'auto' }}>
        <Card
          headStyle={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: theme === 'dark' ? gray._850 : gray._000,
            borderRadius: 0,
          }}
          bodyStyle={{
            padding: 0,
          }}
          style={{
            borderRadius: 0,
          }}
          title={
            <Row justify={'space-between'} align={'middle'}>
              <Col
                flex={'1 1 auto'}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  gap: 0,
                  minHeight: '52px',
                }}
              >
                <Text>Horario</Text>
              </Col>
            </Row>
          }
        >
          {
            // map 24 hours
            [...Array(18).keys()].map((hour) => {
              const calcHour = hour + 7
              return (
                <Row
                  key={calcHour}
                  justify={'space-between'}
                  align={'middle'}
                  style={{ padding: 0 }}
                >
                  <Col
                    flex={'1 1 auto'}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '100%',
                      gap: 0,
                      padding: '0px',
                    }}
                  >
                    <Text>{calcHour}:00</Text>
                  </Col>
                  {calcHour !== 24 && (
                    <Col
                      flex={'1 1 auto'}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                        gap: 0,
                        backgroundColor:
                          theme === 'dark' ? gray._850 : gray._100,
                      }}
                    >
                      <Text>{calcHour}:30</Text>
                    </Col>
                  )}
                </Row>
              )
            })
          }
        </Card>
        {
          // map days
          days.map((day, index) => {
            return (
              <Col flex={'1 1 auto'} key={_.uniqueId()}>
                <Card
                  style={{ height: '100%', borderRadius: 0 }}
                  headStyle={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 1,
                    backgroundColor: theme === 'dark' ? gray._850 : gray._000,
                    borderRadius: 0,
                  }}
                  bodyStyle={{
                    height: '100%',
                    padding: 0,
                    position: 'relative',
                  }}
                  title={
                    <Row justify={'center'} align={'middle'}>
                      <Space
                        direction={'vertical'}
                        align={'center'}
                        style={{
                          width: '100%',
                          gap: '0 !important',
                          minHeight: '52px',
                        }}
                      >
                        <Text style={{ lineHeight: 'auto' }}>
                          {daysOfWeek[index]}
                        </Text>
                        <Text style={{ fontSize: 12 }}>
                          {day.format('DD/MM')}
                        </Text>
                      </Space>
                    </Row>
                  }
                >
                  {
                    // map 24 hours
                    [...Array(18).keys()].map((hour) => {
                      const calcHour = hour + 7
                      return (
                        <Row
                          key={calcHour}
                          justify={'space-between'}
                          align={'middle'}
                          style={{ padding: 0 }}
                        >
                          <Col
                            flex={'1 1 auto'}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '100%',
                              gap: 0,
                              padding: '0px',
                              height: '22px',
                            }}
                          ></Col>
                          {calcHour !== 24 && (
                            <Col
                              flex={'1 1 auto'}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                                gap: 0,
                                backgroundColor:
                                  theme === 'dark' ? gray._850 : gray._100,
                                height: '22px',
                              }}
                            ></Col>
                          )}
                        </Row>
                      )
                    })
                  }

                  {!isLoadingEvents &&
                    events
                      .filter((el) => el.date === day.format('YYYY-MM-DD'))
                      .map((event) => {
                        const initialHour = dayjs('07:00:00', 'HH:mm:ss')
                        const start = dayjs(event.int_time, 'HH:mm:ss')
                        const end = dayjs(event.end_time, 'HH:mm:ss')
                        const duration = end.diff(start, 'minutes') / 60
                        const durationInPixels = duration * 44
                        const height = start.diff(initialHour, 'minutes') / 60

                        return (
                          <div
                            key={event.id}
                            style={{
                              position: 'absolute',
                              top: height * 44,
                              width: '93%',
                              minHeight: `${durationInPixels}px`,
                              height: `${durationInPixels}px`,
                              maxHeight: `${durationInPixels}px`,
                            }}
                          >
                            <Badge.Ribbon
                              text={event?.subject?.name}
                              color={event?.subject?.color}
                            >
                              <Card
                                onClick={() => {
                                  navigate(`/portal/events/${event.id}`)
                                }}
                                style={{
                                  minHeight: `${durationInPixels}px`,
                                  height: `${durationInPixels}px`,
                                  maxHeight: `${durationInPixels}px`,
                                  display: 'flex',
                                  alignItems: 'flex-end',
                                  cursor: 'pointer',
                                }}
                                bodyStyle={{
                                  padding: 8,
                                }}
                              >
                                {event?.students.map((member) => (
                                  <Tooltip
                                    key={member?.id}
                                    title={member?.name}
                                  >
                                    <InlineAvatar
                                      name={member?.name}
                                      src={member?.picture}
                                      size={24}
                                      style={{
                                        marginRight: 0,
                                        backgroundColor: '#87d068',
                                      }}
                                    />
                                  </Tooltip>
                                ))}
                              </Card>
                            </Badge.Ribbon>
                          </div>
                        )
                      })}
                </Card>
              </Col>
            )
          })
        }
      </Row>
    </DashContainer>
  )
}

export default Next5DaysCalendar
