import axios, { AxiosRequestConfig } from 'axios'
import { keycloak } from './Keycloak/Keycloak'

const api = axios.create()

const authInterceptor = (
  request: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  if (keycloak?.token) {
    addToken(request)
    return request
  }

  return new Promise((resolve, reject) => {
    keycloak.onAuthSuccess = () => {
      addToken(request)
      resolve(request)
    }
    keycloak.onAuthError = () => {
      reject(request)
    }
  })
}

const addToken = (request: AxiosRequestConfig) => {
  request.headers.Authorization = `Bearer ${keycloak.token}`
}

api.interceptors.request.use(authInterceptor)

export default api
