import { useKeycloak } from '@react-keycloak/web'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import MenuRoutes, { filterMenu } from '~/components/Layout/menuRoutes'
import Loading from '~/components/Loading'

const Dashboard = () => {
  const { keycloak } = useKeycloak()
  const navigate = useNavigate()

  const itensMenuDashboard = filterMenu(MenuRoutes, keycloak).find(
    (item) => item.path === '/portal/dashboard',
  )

  useEffect(() => {
    if (itensMenuDashboard) {
      navigate(itensMenuDashboard.children[0].path)
    }
  }, [keycloak])

  return <Loading />
}

export default Dashboard
