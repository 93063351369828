import { Card, Space, Tag } from 'antd'
import React, { useEffect } from 'react'
import useTitleStore from '../../../stores/useTitleStore'
import { ColumnsType } from 'antd/es/table'
import { InlineAvatar } from '../../../components/InlineAvatar'
import api from '../../../services/axios'
import { useQuery } from '@tanstack/react-query'
import Table from '../../../components/Table'

interface DataType {
  key: React.Key
  name: string
  username: string
  email: string
  phone: string
  picture: string
  profiles: string
}

const profilesColors = {
  admin: 'red',
  secretaria: 'blue',
  professores: 'green',
  alunos: 'purple',
}

const Users = () => {
  const { setTitle } = useTitleStore()

  const { isLoading, data } = useQuery(['listAllUsers'], () => {
    return api
      .get('/api/users')
      .then((res) => res.data)
      .catch((err) => {})
  })

  useEffect(() => {
    setTitle({
      title: 'Usuários',
      subTitle: 'Gerenciar usuários',
      routes: [
        { path: '/portal/admin', breadcrumbName: 'Administrativo' },
        {
          path: '/portal/admin/users',
          breadcrumbName: 'Usuários',
        },
      ],
    })
  }, [])

  const columns: ColumnsType<DataType> = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sortDirections: ['ascend', 'descend'],
      defaultSortOrder: 'descend',
      filters: (data || []).map((user) => ({
        text: user.name,
        value: user.name,
      })),
      onFilter: (value: string, record) => record.name.startsWith(value),
      filterSearch: true,
      sorter: (a, b) => b.name.localeCompare(a.name),
      render: (text, item) => (
        <a>
          <InlineAvatar name={text} src={item?.picture} />
          {text}
        </a>
      ),
    },
    {
      title: 'Usuário',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Perfil',
      dataIndex: 'profiles',
      key: 'profiles',
      // filter map data unique profiles split by comma
      filters: [
        ...new Set(
          (data || [])
            .map((user) => user.profiles)
            .join(',')
            .split(','),
        ),
      ]
        .filter((profile) => profile)
        .map((profile) => ({
          text: profile.toUpperCase(),
          value: profile,
        })),
      onFilter: (value: string, record) => record.profiles.indexOf(value) === 0,
      render: (text) => {
        // string to array split by comma and remove duplicates
        const unique = [...new Set(text?.split(','))]
        return unique.map((item) => (
          <Tag key={item?.toString()} color={profilesColors[item]}>
            {item.toUpperCase()}
          </Tag>
        ))
      },
    },
    {
      title: 'Ações',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
    },
  ]

  return (
    <Space direction={'vertical'} size={16} style={{ display: 'flex' }}>
      {/* <Card></Card> */}
      <Card title="Listagem de Usuários" extra={<a href="#">+ Adicionar</a>}>
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={data}
          size={'small'}
        />
      </Card>
    </Space>
  )
}

export default Users
