import React, { useEffect } from 'react'
import useTitleStore from '~/stores/useTitleStore'
import api from '~/services/axios'
import { useQuery } from '@tanstack/react-query'
import Table from '~/components/Table'
import { Card, Space } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useNavigate } from 'react-router-dom'

interface DataType {
  id: string
  name: string
}

const Profiles = () => {
  const navigate = useNavigate()
  const { setTitle } = useTitleStore()

  useEffect(() => {
    setTitle({
      title: 'Perfis',
      subTitle: 'Gerenciar perfis de usuários',
      routes: [
        { path: '/portal/admin', breadcrumbName: 'Administrativo' },
        {
          path: '/portal/admin/profiles',
          breadcrumbName: 'Perfis',
        },
      ],
    })
  }, [])

  const { isLoading, data } = useQuery(['listAllProfiles'], () => {
    return api
      .get('/api/profiles')
      .then((res) => res.data)
      .catch((err) => {})
  })

  const columns: ColumnsType<DataType> = [
    {
      title: 'Perfil',
      dataIndex: 'name',
      key: 'name',
      render: (text) =>
        text.toUpperCase().charAt(0) + text.slice(1).toLowerCase(),
    },
    {
      title: 'Ações',
      dataIndex: 'action',
      key: 'action',
      width: '60px',
      render: (text, record) => (
        <Space size="middle">
          <a onClick={() => navigate(`${record.id}`)}>Editar</a>
          <a>Excluir</a>
        </Space>
      ),
    },
  ]

  return (
    <Space direction={'vertical'} size={16} style={{ display: 'flex' }}>
      {/* <Card></Card> */}
      <Card title="Listagem de Perfis" extra={<a href="#">+ Adicionar</a>}>
        <Table
          rowKey="id"
          loading={isLoading}
          columns={columns}
          dataSource={data}
          size={'small'}
        />
      </Card>
    </Space>
  )
}

export default Profiles
