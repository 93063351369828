import dayjs from 'dayjs'

// function to return data string to dd/mm/yyyy hh:mm format
export const MaskDateWithTime = (date: string) => {
  const dateObj = new Date(date)
  const month =
    dateObj.getMonth() + 1 < 10
      ? `0${dateObj.getMonth() + 1}`
      : dateObj.getMonth() + 1
  const day =
    dateObj.getDate() < 10 ? `0${dateObj.getDate()}` : dateObj.getDate()
  const year = dateObj.getFullYear()
  const hours =
    dateObj.getHours() < 10 ? `0${dateObj.getHours()}` : dateObj.getHours()
  const minutes =
    dateObj.getMinutes() < 10
      ? `0${dateObj.getMinutes()}`
      : dateObj.getMinutes()

  return `${day}/${month}/${year} ${hours}:${minutes}`
}

// function to return data string to dd/mm/yyyy format
export const MaskDate = (date: string) => {
  if (!date) return ''
  const dateObj = dayjs(date)
  const month = dateObj.month() + 1
  const monthTwoDigits = month < 10 ? `0${month}` : month
  const day = dateObj.date()
  const dayTwoDigits = day < 10 ? `0${day}` : day
  const year = dateObj.year()

  return `${dayTwoDigits}/${monthTwoDigits}/${year}`
}

// function to return data string to mm/yyyy format
export const MaskMonthYear = (date: string) => {
  if (!date) return ''
  const dateObj = dayjs(date)
  const month = dateObj.month() + 1
  const monthTwoDigits = month < 10 ? `0${month}` : month
  const year = dateObj.year()

  return `${monthTwoDigits}/${year}`
}

export const UnMaskDate = (date: string) => {
  if (!date) return ''
  const dateObj = dayjs(date)
  const month = dateObj.month() + 1
  const monthTwoDigits = month < 10 ? `0${month}` : month
  const day = dateObj.date()
  const year = dateObj.year()

  return `${year}-${monthTwoDigits}-${day}`
}

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

// mask number float money to R$ 0,00
export const MaskMoney = (value: any) => {
  // if value is null or undefined, return 0
  if (!value) {
    return 'R$ 0,00'
  }

  // if value is a string, convert to float
  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  return value.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
  })
}

// unmask money to float
export const UnMaskMoney = (value: any) => {
  if (!value) {
    return 0
  }

  if (typeof value === 'string') {
    value = parseFloat(value)
  }

  return value
}

// mask number float money to 0,00%
export const MaskPercent = (value: number) => {
  return value.toLocaleString('pt-BR', {
    style: 'percent',
    minimumFractionDigits: 2,
  })
}
