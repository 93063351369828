import { useQuery } from '@tanstack/react-query'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Tabs,
  Tag,
  Typography,
} from 'antd'
import modal from 'antd/es/modal'
import React, { useState } from 'react'
import Link from '~/components/Link'
import Table from '~/components/Table'
import { MaskDate, MaskMoney, MaskMonthYear } from '~/helpers/Masks'
import api from '~/services/axios'
import { queryClient } from '~/services/queryClient'

interface PaymentsProps {
  id: string
}

const DepositContent = ({ isLoadingPayments, payments }) => {
  const openPayment = payments?.find((item) => item.date !== '')?.payments

  const expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Data',
        dataIndex: ['event', 'date'],
        key: 'date',
        render: (date) => MaskDate(date),
      },
      {
        title: 'Disciplina',
        dataIndex: ['event', 'subject'],
        key: 'subject',
        filters: openPayment
          ?.map((item) => ({
            text: item?.event?.subject?.name,
            value: item?.event?.subject?.id,
          }))
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.value === item.value)
          ),
        onFilter: (value, record) => record.event.subject.id === value,
        render: (item, _) => <Tag color={item?.color}>{item?.name}</Tag>,
      },
      {
        title: 'Modalidade',
        dataIndex: ['event', 'modality', 'name'],
        key: 'modality',
      },

      {
        title: 'Valor',
        dataIndex: 'amount',
        key: 'amount',
        render: (item, _) => MaskMoney(item),
      },
      {
        title: 'Aula',
        dataIndex: ['event'],
        key: 'event',
        width: 150,
        render: (item, _) => (
          <>
            <Link to={`/portal/events/${item.id}`}>Ver aula</Link>
          </>
        ),
      },
    ]

    return (
      <Table
        loading={isLoadingPayments}
        tableLayout="fixed"
        dataSource={record.payments}
        columns={columns}
      />
    )
  }

  return (
    <Table
      loading={isLoadingPayments}
      tableLayout="fixed"
      expandable={{
        expandedRowRender,
        defaultExpandedRowKeys: ['0'],
      }}
      dataSource={payments?.filter((item) => item.date !== '')}
      columns={[
        {
          title: 'Data',
          dataIndex: ['date'],
          key: 'date',
          render: (date) => MaskDate(date),
        },
        {
          title: 'Valor',
          dataIndex: ['total'],
          key: 'total',
          render: (total) => MaskMoney(total),
        },
        {
          title: 'Desconto',
          dataIndex: ['discount'],
          key: 'discount',
          render: (discount) => MaskMoney(discount),
        },
        {
          title: 'Abatimento',
          dataIndex: ['allowance'],
          key: 'allowance',
          render: (allowance) => MaskMoney(allowance),
        },
        {
          title: 'Nº de Aulas',
          render: (_, item) => item?.payments?.length,
        },
      ]}
    />
  )
}

const OpenContent = ({
  isLoadingPayments,
  openPayment,
  setSelectedRowKeys,
  selectedRowKeys,
  id,
}) => {
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const { Text } = Typography
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled: record.status.name !== 'Pendente', // Column configuration not to be checked
    }),
  }

  const selectedPaymentsTotal = openPayment
    ?.filter((item) => selectedRowKeys.includes(item.id))
    .reduce((acc, item) => acc + Number(item.amount), 0)

  const [isModalDiscountVisible, setIsModalDiscountVisible] = useState(false)
  const [formDiscount] = Form.useForm()
  const discountValue = Form.useWatch('discount', formDiscount)
  const allowanceValue = Form.useWatch('allowance', formDiscount)
  const discountPercentageWatch = Form.useWatch(
    'discount_percent',
    formDiscount
  )
  const allowancePercentageWatch = Form.useWatch(
    'allowance_percent',
    formDiscount
  )

  const [isModalPaymentVisible, setIsModalPaymentVisible] = useState(false)

  const handleDiscount = () => {
    const discount = Number(formDiscount.getFieldsValue().discount || 0)
    const allowance = Number(formDiscount.getFieldsValue().allowance || 0)

    api
      .post(`/api/teachers/${id}/update-discount`, {
        payments: selectedRowKeys,
        discount,
        allowance,
      })
      .then((res) => {
        notification.success({
          message: 'Desconto aplicado com sucesso',
        })

        queryClient.invalidateQueries(['teacher_payments', id])
      })
      .catch((err) => {
        notification.error({
          message: 'Erro ao aplicar desconto',
        })
      })

    setIsModalDiscountVisible(false)
    formDiscount.resetFields()
  }

  const recalculateDiscounts = (changeOn) => {
    const total = selectedPaymentsTotal || 0

    if (changeOn === 'R$') {
      formDiscount.setFieldsValue({
        discount_percent: Number((discountValue / total) * 100 || 0).toFixed(2),
      })
    }

    if (changeOn === '%') {
      formDiscount.setFieldsValue({
        discount: Number(total * (discountPercentageWatch / 100) || 0).toFixed(
          2
        ),
      })
    }
  }

  const recalculatedAllowance = (changeOn) => {
    const total = selectedPaymentsTotal || 0

    if (changeOn === 'R$') {
      formDiscount.setFieldsValue({
        allowance_percent: Number((allowanceValue / total) * 100 || 0).toFixed(
          2
        ),
      })
    }

    if (changeOn === '%') {
      formDiscount.setFieldsValue({
        allowance: Number(
          total * (allowancePercentageWatch / 100) || 0
        ).toFixed(2),
      })
    }
  }
  const handlePayment = () => {
    api
      .post(`/api/teachers/${id}/checkout-payments`, {
        payments: selectedRowKeys,
      })
      .then((res) => {
        notification.success({
          message: 'Pagamento realizado com sucesso',
        })

        queryClient.invalidateQueries(['teacher_payments', id])
      })
      .catch((err) => {
        notification.error({
          message: 'Erro ao realizar pagamento',
        })
      })

    setIsModalPaymentVisible(false)
  }

  return (
    <>
      <span>Pagamentos Selecionados: {selectedRowKeys?.length}</span>

      <Space
        align={'end'}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 8,
        }}
      >
        <Button
          disabled={!selectedRowKeys?.length}
          type="primary"
          onClick={() => {
            modal.confirm({
              title: 'Confirmar Pagamento',
              onOk: () => handlePayment(),
              okText: 'Confirmar',
              content: (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Row justify={'end'}>
                    <Text>
                      <Text strong>{selectedRowKeys?.length}</Text> pagamentos
                      selecionados
                    </Text>
                  </Row>
                  <br />
                  <Row justify={'space-between'}>
                    <Text strong>Valor original:</Text>
                    <Text>
                      {MaskMoney(
                        openPayment
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce((acc, item) => acc + Number(item.amount), 0)
                      )}
                    </Text>
                  </Row>
                  <Row justify={'space-between'}>
                    <Text strong>Desconto:</Text>
                    <Text>
                      {MaskMoney(
                        openPayment
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce((acc, item) => acc + Number(item.discount), 0)
                      )}{' '}
                      (
                      {MaskMoney(
                        openPayment
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce(
                            (acc, item) => acc + Number(item.discount),
                            0
                          ) / selectedRowKeys?.length
                      )}{' '}
                      por aula)
                    </Text>
                  </Row>
                  <Row justify={'space-between'}>
                    <Text strong>Abatimento:</Text>
                    <Text>
                      {MaskMoney(
                        openPayment
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce(
                            (acc, item) => acc + Number(item.allowance),
                            0
                          )
                      )}{' '}
                      (
                      {MaskMoney(
                        openPayment
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce(
                            (acc, item) => acc + Number(item.allowance),
                            0
                          ) / selectedRowKeys?.length
                      )}{' '}
                      por aula)
                    </Text>
                  </Row>
                  <Row justify={'space-between'}>
                    <Text strong>Valor a pagar:</Text>
                    <Text>
                      {MaskMoney(
                        openPayment
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce((acc, item) => acc + Number(item.amount), 0) -
                        (discountValue || 0) -
                        (allowanceValue || 0)
                      )}
                    </Text>
                  </Row>
                  <br />
                </Space>
              ),
            })
          }}
        >
          Confirmar Pagamento
        </Button>
      </Space>

      <Table
        loading={isLoadingPayments}
        dataSource={openPayment}
        rowSelection={rowSelection}
        tableLayout="fixed"
        rowKey="id"
        columns={[
          {
            title: 'Data',
            dataIndex: ['event', 'date'],
            key: 'date',
            filters: openPayment
              .map((item) => ({
                // return text and value mm/yyyy
                text: MaskMonthYear(item?.event?.date),
                value: MaskMonthYear(item?.event?.date),
              }))
              // remove duplicate and order by date
              .filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) => t.text === item.text && t.value === item.value
                  )
              )
              // sort mm/yyyy string ascending
              .sort(
                (a, b) => new Date('01/' + a.value) - new Date('01/' + b.value)
              ),

            onFilter: (value, record) =>
              MaskMonthYear(record?.event?.date) === value,
            render: (date) => MaskDate(date),
          },
          {
            title: 'Aula',
            dataIndex: ['event'],
            key: 'event',
            width: 150,
            render: (item, _) => (
              <>
                <Link to={`/portal/events/${item.id}`}>
                  <Tag color={item?.status?.color}>{item?.status?.name}</Tag>
                </Link>
              </>
            ),
          },
          {
            title: 'Disciplina',
            dataIndex: ['event', 'subject'],
            key: 'subject',
            filters: openPayment
              ?.map((item) => ({
                text: item?.event?.subject?.name,
                value: item?.event?.subject?.id,
              }))
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.value === item.value)
              ),
            onFilter: (value, record) => record.event.subject.id === value,
            render: (item, _) => <Tag color={item?.color}>{item?.name}</Tag>,
          },
          {
            title: 'Modalidade',
            dataIndex: ['event', 'modality', 'name'],
            key: 'modality',
          },

          {
            title: 'Valor',
            dataIndex: 'amount',
            key: 'amount',
            render: (item, _) => MaskMoney(item),
          },
          {
            title: 'Data de Pagamento',
            dataIndex: 'date',
            key: 'date',
            filters: openPayment
              ?.map((item) => ({
                text: MaskDate(item.date),
                value: MaskDate(item.date),
              }))
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.value === item.value)
              ),
            onFilter: (value, record) =>
              MaskDate(record.date).indexOf(value) === 0,
            render: (item, _) => MaskDate(item),
          },

          {
            title: 'Status',
            dataIndex: ['status', 'name'],
            key: 'status',
            filters:
              // map payments status unique
              openPayment
                ?.map((item) => ({
                  text: item.status?.name,
                  value: item.status?.name,
                }))
                .filter(
                  (item, index, self) =>
                    index === self.findIndex((t) => t.value === item.value)
                ),
            onFilter: (value, record) =>
              record.status.name.indexOf(value) === 0,
          },
        ]}
      />

      <Modal
        title="Confirmar Pagamento"
        open={isModalPaymentVisible}
        onOk={handlePayment}
        onCancel={() => setIsModalPaymentVisible(false)}
      >
        {selectedRowKeys?.length} pagamentos selecionados
        {/*  display total */}
        <p>
          Valor:{' '}
          {MaskMoney(
            openPayment
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.amount), 0)
          )}
        </p>
        {/* set discount */}
        <p>
          Desconto aplicado:{' '}
          {MaskMoney(
            openPayment
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.discount), 0)
          )}{' '}
          (
          {MaskMoney(
            openPayment
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.discount), 0) /
            selectedRowKeys?.length
          )}{' '}
          por aula)
        </p>
        {/* set allowance */}
        <p>
          Abatimento aplicado:{' '}
          {MaskMoney(
            openPayment
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.allowance), 0)
          )}{' '}
          (
          {MaskMoney(
            openPayment
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.allowance), 0) /
            selectedRowKeys?.length
          )}{' '}
          por aula)
        </p>
        <p>
          Valor pago:{' '}
          {MaskMoney(
            openPayment
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.amount), 0) -
            (discountValue || 0) -
            (allowanceValue || 0)
          )}
        </p>
      </Modal>

      <Modal
        title="Descontos e abonos"
        open={isModalDiscountVisible}
        onOk={handleDiscount}
        onCancel={() => {
          setIsModalDiscountVisible(false)
          formDiscount.resetFields()
        }}
      >
        {selectedRowKeys?.length} pagamentos selecionados
        {/*  display total */}
        <p>
          Valor:{' '}
          {MaskMoney(
            openPayment
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.amount), 0)
          )}
        </p>
        {/* set discount */}
        <p>
          Desconto: {MaskMoney(discountValue || 0)} (
          {MaskMoney((discountValue || 0) / selectedRowKeys?.length)} por aula)
        </p>
        {/* set allowance */}
        <p>
          Abatimento: {MaskMoney(allowanceValue || 0)} (
          {MaskMoney((allowanceValue || 0) / selectedRowKeys?.length)} por aula)
        </p>
        <p>
          Valor a pagar:{' '}
          {MaskMoney(
            openPayment
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.amount), 0) -
            (discountValue || 0) -
            (allowanceValue || 0)
          )}
        </p>
        <Form
          form={formDiscount}
          name="descounts"
          layout="vertical"
          onFinish={() => { }}
        // wrapperCol={{ span: 10 }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="discount"
                style={{ marginBottom: '0px' }}
                label="Desconto (R$)"
              >
                <Input
                  placeholder="Desconto (R$)"
                  prefix={'R$'}
                  onBlur={() => recalculateDiscounts('R$')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="discount_percent"
                style={{ marginBottom: '0px' }}
                label="Desconto (%)"
              >
                <Input
                  placeholder="Desconto (%)"
                  suffix={'%'}
                  onBlur={() => recalculateDiscounts('%')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="allowance" label="Abatimento (R$)">
                <Input
                  placeholder="Abatimento (R$)"
                  prefix={'R$'}
                  onBlur={() => recalculatedAllowance('R$')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="allowance_percent" label="Abatimento (%)">
                <Input
                  placeholder="Abatimento (%)"
                  suffix={'%'}
                  onBlur={() => recalculatedAllowance('%')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

const Payments = ({ id }: PaymentsProps) => {
  const [openPayment, setOpenPayment] = useState([])

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])

  const { data: payments, isLoading: isLoadingPayments } = useQuery(
    ['teacher_payments', id],
    () =>
      api.get(`/api/teachers/${id}/payments`).then((res) => {
        setSelectedRowKeys([])
        const data = res.data.map((item) => ({ key: item.date, ...item }))
        setOpenPayment(
          data
            .find((item) => item.date === '')
            ?.payments.filter((item) => item.status.name !== 'Cancelado')
            .sort(
              (a, b) =>
                new Date(b.event.date).getTime() -
                new Date(a.event.date).getTime()
            ) || []
        )
        return data
      }),
    {
      refetchOnWindowFocus: false,
    }
  )

  return (
    <Row gutter={16} style={{ width: '100%' }}>
      <Col span={24}>
        <Tabs
          defaultActiveKey="1"
          tabPosition="top"
          items={[
            {
              key: '1',
              label: 'Abertos',
              children: (
                <OpenContent
                  openPayment={openPayment}
                  isLoadingPayments={isLoadingPayments}
                  setSelectedRowKeys={setSelectedRowKeys}
                  id={id}
                  selectedRowKeys={selectedRowKeys}
                />
              ),
            },
            {
              key: '2',
              label: 'Pagamento finalizado',
              children: (
                <DepositContent
                  payments={payments}
                  isLoadingPayments={isLoadingPayments}
                />
              ),
            },
          ]}
        />
      </Col>
    </Row>
  )
}

export default Payments
