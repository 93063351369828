import { create } from 'zustand'
import React from 'react'

interface ContextProps {
  currentProfile: string | null
  userKeycloak: any
  setUserKeycloak: (userKeycloak: any) => void
  setCurrentProfile: (profile: string) => void
}

const useContextStore = create<ContextProps>((set, get) => ({
  currentProfile: null,
  userKeycloak: null,
  setUserKeycloak: (userKeycloak) => {
    set({ userKeycloak })
  },
  setCurrentProfile: (currentProfile) => {
    set({ currentProfile })
  },
}))

export default useContextStore
