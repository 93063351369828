import React, { useEffect, useState } from 'react'
import useTitleStore from '../../stores/useTitleStore'
import { Button, Card, Space } from 'antd'
import { MdAdd } from 'react-icons/md'
import CreateNewTeacherModal from '~/pages/Teachers/CreateNewTeacherModal'
import ListAllTeachers from '~/pages/Teachers/ListAllTeachers'

const Teachers = () => {
  const { setTitle } = useTitleStore()
  const [modalNewTeacher, setModalNewTeacher] = useState(false)

  useEffect(() => {
    setTitle({
      title: 'Professores',
      subTitle: 'Gerenciar professores',
    })
  }, [])

  return (
    <Space direction={'vertical'} size={16} style={{ display: 'flex' }}>
      <Card
        title="Listagem de Professores"
        extra={
          <Button
            icon={<MdAdd />}
            type="primary"
            onClick={() => setModalNewTeacher(true)}
          >
            Novo Professor
          </Button>
        }
      >
        <ListAllTeachers />
      </Card>

      <CreateNewTeacherModal
        modalNewTeacher={modalNewTeacher}
        setModalNewTeacher={setModalNewTeacher}
      />
    </Space>
  )
}

export default Teachers
