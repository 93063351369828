import { Avatar } from 'antd'
import React from 'react'

export const InlineAvatar = ({ name, src, ...props }) => {
  const getInitials = (name) => {
    const splitName = name?.split(' ')
    return splitName?.[0]?.[0] + splitName?.[1]?.[0]
  }

  return (
    <Avatar 
      src={src} 
      style={{ backgroundColor: '#87d068', marginRight: '0.3rem' }} {...props}
    >
      {getInitials(name)}
    </Avatar>
  )
}
