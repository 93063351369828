import { Space } from 'antd'
import React from 'react'
import AvatarDropdown from '~/components/Layout/AvatarDropdown'

const RightContent = () => {
  return (
    <Space split={' '}>
      <AvatarDropdown />
    </Space>
  )
}

export default RightContent
