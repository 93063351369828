import { useQuery } from '@tanstack/react-query'
import {
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  notification,
  Row,
  Select,
  Space,
  TimePicker,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import React, { useCallback, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import api from '~/services/axios'
import useTitleStore from '~/stores/useTitleStore'
import { InlineAvatar } from '~/components/InlineAvatar'
import { queryClient } from '~/services/queryClient'
import _ from 'lodash'
import { IFilters } from '~/types/Global.types'

const EventCreate = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const query = new URLSearchParams(useLocation().search)
  const origin = query.get('origin') ?? 'profile'

  const [processing, setProcessing] = React.useState(false)

  const [studentQueryId, setStudentQueryId] = React.useState(
    query.get('student'),
  )

  // watch type
  const watchType = Form.useWatch('type', form)
  const watchDuration = Form.useWatch('duration', form)
  const watchInitTime = Form.useWatch('init_hour', form)
  const watchStudents = Form.useWatch('students', form)
  const watchModalities = Form.useWatch('modality', form)
  const watchGrade = Form.useWatch('grade', form)
  const watchDate = Form.useWatch('date', form)

  const [students, setStudents] = React.useState([])
  const [teachers, setTeachers] = React.useState<IFilters[]>([])
const [blockedDuration, setBlockedDuration] = React.useState(false);
  const [filteredRooms, setFilteredRooms] = React.useState([])

  const { setTitle } = useTitleStore()

  const { Title } = Typography

  useEffect(() => {
    if (origin === 'student') {
      setTitle({
        title: 'Agendar nova aula',
        subTitle: 'Agendar nova aula para o aluno',
        routes: [
          {
            path: '/portal/students',
            breadcrumbName: 'Alunos',
          },
          {
            path: '/event/create',
            breadcrumbName: 'Agendar nova aula',
          },
        ],
      })
    } else {
      setTitle({
        title: 'Nova Aula',
        subTitle: 'Criar nova aula',
      })
    }
  }, [])

  // on type change
  useEffect(() => {
    if (watchType === undefined) {
      return
    }

    const type = availableServices.find(
      (service) => service.value === watchType,
    )

    if (type === undefined) {
      return
    }

    if (type.label === 'Grupo') {
      form.setFieldsValue({
        duration: 2.5,
      })
      setBlockedDuration(true)
    }else{
      setBlockedDuration(false);
    }
  }, [watchType])

  // on date change
  useEffect(() => {
    if (watchDate === undefined) {
      return
    }

    const id = watchStudents[0]

    if (id === undefined) {
      return
    }

    api.get(`/api/students/${id}`).then((res) => {
      const { grades } = res.data
      const year = form.getFieldValue('date').year()
      const currentGrade = grades.find((g) => g.year === year)

      if (currentGrade === undefined) {
        resquestGradeUpdate(res.data.id)
        form.setFieldValue('grade', null)
        return
      }

      form.setFieldValue('grade', currentGrade.id)

      return res.data
    })
  }, [watchDate])

  // on duration change
  useEffect(() => {
    if (watchDuration === undefined) {
      return
    }

    form.setFieldsValue({
      end_hour: calculateDuration(
        form.getFieldValue('init_hour') || defaultHour(),
        watchDuration,
      ),
    })
  }, [watchDuration])

  // on init_hour change
  useEffect(() => {
    if (watchInitTime === undefined) {
      return
    }

    form.setFieldsValue({
      end_hour: calculateDuration(watchInitTime, watchDuration),
    })
  }, [watchInitTime])

  useEffect(() => {
    if (watchStudents === undefined) return

    const id = watchStudents[0]

    if (studentQueryId !== id && watchStudents.length === 0) {
      setStudents([studentQueryId])
      form.setFieldValue('students', [studentQueryId])

      return
    }

    if (id === undefined) {
      form.setFieldValue('grade', null)
      return
    }

    const student = queryClient.getQueryData(['student', id])
    const { grades } = student || {}

    if (student === undefined) return

    const year = form.getFieldValue('date').year()
    const currentGrade = grades.find((g) => g.year === year)

    if (currentGrade === undefined) {
      resquestGradeUpdate(student?.id)
      form.setFieldValue('grade', null)
      return
    }

    form.setFieldValue('grade', currentGrade.id)
  }, [watchStudents])

  useEffect(() => {
    if (watchModalities === undefined) {
      return
    }

    filterRooms(watchModalities)
  }, [watchModalities])

  useEffect(() => {
    if (watchGrade === undefined) {
      return
    }

    const newArray = students.slice(0, 1) || []

    setStudents(newArray)
    form.setFieldValue('students', newArray)
  }, [watchGrade])

  const defaultHour = () => {
    const now = dayjs()
    const minutes = now.minute()
    const minutesRounded = minutes < 30 ? 0 : 30
    return now.minute(minutesRounded)
  }

  const calculateDuration = (init, duration) => {
    const endHour = init.add(duration, 'hour')
    return endHour
  }

  const { data: availableStudents, isLoading: isLoadingStudents } = useQuery(
    ['studentsListSelect', watchGrade],
    () =>
      api
        .get('/api/students', {
          params: {
            grade: watchGrade,
          },
        })
        .then((res) => {
          return res.data.map((student) => ({
            label: student.name,
            value: student.id,
          }))
        }),
  )

  const { data: availableSubjects, isLoading: isLoadingSubjects } = useQuery(
    ['subjectsListSelect'],
    () =>
      api.get('/api/subjects').then((res) => {
        const select = res.data.map((subject) => ({
          label: subject.name,
          value: subject.id,
        }))

        form.setFieldsValue({
          subject: select[0].value,
        })

        return select
      }),
    {
      refetchOnWindowFocus: false,
    },
  )

  const { data: availableTeachers, isLoading: isLoadingTeachers } = useQuery(
    ['teachersListSelect', form.getFieldValue('subject')],
    () =>
      api
        .get('/api/filters/teachers', {
          params: {
            active: true,
            subject_id: form.getFieldValue('subject'),
          },
        })
        .then((res) => {
          const select = res.data

          if (_.isEmpty(select)) {
            setTeachers([])

            form.setFieldsValue({
              teachers: [],
            })

            notification.warning({
              key: 'select-teacher-warning',
              message: 'Atenção',
              description: 'Não há professores disponíveis para essa matéria.',
            })

            return []
          }

          setTeachers([select[0]?.value])

          form.setFieldsValue({
            teachers: [select[0]?.value],
          })

          return select
        }),
    {
      enabled: !!availableSubjects,
      refetchOnWindowFocus: false,
    },
  )

  const { data: availableModalities, isLoading: isLoadingModalities } =
    useQuery(
      ['modalitiesListSelect'],
      () =>
        api.get('/api/modalities').then((res) => {
          const select = res.data.map((modality) => ({
            label: modality.name,
            value: modality.id,
          }))

          form.setFieldsValue({
            modality: select[0].value,
          })

          return select
        }),
      {
        refetchOnWindowFocus: false,
      },
    )

  const { data: availableRooms, isLoading: isLoadingRooms } = useQuery(
    ['roomsListSelect'],
    () =>
      api.get('/api/rooms').then((res) => {
        const select = res.data.map((room) => ({
          label: room.name,
          value: room.id,
          modality_id: room.modality_id,
          capacity: room.capacity,
        }))

        setFilteredRooms(select)

        return select
      }),
    {
      enabled: !!availableModalities,
      refetchOnWindowFocus: false,
    },
  )

  const { data: availableServices, isLoading: isLoadingServices } = useQuery(
    ['servicesListSelect'],
    () =>
      api.get('/api/services').then((res) => {
        const select = res.data.map((service) => ({
          label: service.name,
          value: service.id,
        }))

        form.setFieldsValue({
          type: [select[0].value],
        })

        return select
      }),
    {
      refetchOnWindowFocus: false,
    },
  )

  const { data: availableGrades, isLoading: isLoadingGrades } = useQuery(
    ['gradesListSelect'],
    () =>
      api.get('/api/grades').then((res) => {
        const select = res.data.map((grade) => ({
          label: grade.type,
          value: grade.id,
        }))

        return select
      }),
    {
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (!isLoadingRooms) {
      filterRooms(form.getFieldValue('modality'))
    }
  }, [isLoadingRooms])

  const filterRooms = useCallback(
    (modalityId) => {
      if (!availableRooms) return

      const filtered = availableRooms?.filter(
        (room) => room.modality_id === modalityId,
      )
      setFilteredRooms(filtered)
      form.setFieldsValue({
        room: filtered[0]?.value,
      })
    },
    [availableRooms],
  )

  const StudentCard = ({ id, index }) => {
    const { data: student, isLoading } = useQuery(
      ['student', id],
      () =>
        api.get(`/api/students/${id}`).then((res) => {
          if (index === 0) {
            const year = form.getFieldValue('date').year()
            const currentGrade = res.data.grades.find((g) => g.year === year)
            if (!currentGrade) {
              resquestGradeUpdate(res.data.id)
              return res.data
            } else {
              form.setFieldsValue({
                grade: currentGrade.id,
              })
            }
          }

          return res.data
        }),
      {
        refetchOnWindowFocus: false,
      },
    )

    return (
      <Card key={id} loading={isLoading}>
        <InlineAvatar name={student?.name} src={student?.picture} />
        <>{student?.name}</>
      </Card>
    )
  }

  const TeacherCard = ({ id }) => {
    const { data: teacher, isLoading } = useQuery(
      ['teacher', id],
      () => api.get(`/api/teachers/${id}`).then((res) => res.data),
      {
        refetchOnWindowFocus: false,
      },
    )

    return (
      <Card key={id} loading={isLoading}>
        <InlineAvatar name={teacher?.name} src={teacher?.picture} />
        <>{teacher?.name}</>
      </Card>
    )
  }

  const createEvent = async (values) => {
    setProcessing(true)
    const { students, teachers, date, end_hour, init_hour, ...rest } = values

    const event = {
      date: date.format('YYYY-MM-DD'),
      int_time: init_hour.format('HH:mm'),
      end_time: end_hour.format('HH:mm'),

      service_id: _.isArray(rest.type) ? rest.type[0] : rest.type,
      room_id: rest.room,
      subject_id: rest.subject,
      modality_id: rest.modality,
      grade_id: rest.grade,

      students,
      teachers,
    }

    const studentsWithoutGrade = []

    for (const student of students) {
      const { data } = await api.get(`/api/students/${student}`)
      const { grades } = data
      const year = form.getFieldValue('date').year()
      const currentGrade = grades.find((g) => g.year === year)

      if (currentGrade === undefined) {
        studentsWithoutGrade.push(student)
      }
    }

    if (studentsWithoutGrade.length > 0) {
      studentsWithoutGrade.forEach((id) => {
        resquestGradeUpdate(id)
      })
      return
    }

    api
      .post('/api/events', event)
      .then((res) => {
        notification.success({
          key: 'create-event',
          message: 'Aula criada com sucesso!',
          btn: (
            <Button
              type={'primary'}
              onClick={() => {
                navigate(`/portal/events/${res?.data?.id}`)
                notification.destroy('create-event')
              }}
            >
              Ver aula
            </Button>
          ),
        })
        setProcessing(false)
      })
      .catch((err) => {
        notification.error({
          key: 'create-event-error',
          message: 'Erro ao criar aula!',
          description: err.response.data.message,
        })
        setProcessing(false)
      })
  }

  const resquestGradeUpdate = async (id) => {
    const url = `/portal/students/${id}?view=profile`

    const { name } =
      queryClient.getQueryData(['student', id]) ||
      (await api.get(`/api/students/${id}`).then((res) => res.data))

    notification.error({
      message: 'Erro',
      description: `Aluno ${name} não possui turma para o ano selecionado`,
      btn: (
        <Button
          type="primary"
          onClick={() => window.open(url, '_blank', 'noreferrer')}
        >
          Atualizar
        </Button>
      ),
    })
  }

  return (
    <Space direction={'vertical'} size={16} style={{ display: 'flex' }}>
      <Card title={'Nova solicitação'} bordered={false}>
        <Form
          form={form}
          layout="vertical"
          onFinish={(values) => {
            createEvent(values)
          }}
        >
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <Form.Item
                key={'date'}
                name={'date'}
                label={'Data'}
                initialValue={dayjs()}
              >
                <DatePicker format={'DD/MM/YYYY'} allowClear={false} />
              </Form.Item>
            </Col>

            <Col xs={24} lg={12}>
              <Row gutter={[8, 8]}>
                <Col span={8}>
                  <Form.Item
                    key={'init_hour'}
                    name={'init_hour'}
                    label={'Inicio'}
                    initialValue={defaultHour()}
                  >
                    <TimePicker
                      allowClear={false}
                      format={'HH:mm'}
                      minuteStep={5}
                      onChange={(time, timeString) => {
                        console.log(time, timeString)
                      }}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    key={'duration'}
                    name={'duration'}
                    label={'Duração'}
                    initialValue={1.0}
                  >
                    <InputNumber
                      min={0.5}
                      max={4.0}
                      step={0.5}
                      onChange={(value) => {
                        form.setFieldsValue({
                          end_hour: calculateDuration(
                            form.getFieldValue('init_hour') || defaultHour(),
                            value,
                          ),
                        })
                      }}
                      disabled={blockedDuration}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    key={'end_hour'}
                    name={'end_hour'}
                    label={'Fim'}
                    initialValue={calculateDuration(
                      form.getFieldValue('init_hour') || defaultHour(),
                      form.getFieldValue('duration') || 0.5,
                    )}
                  >
                    <TimePicker
                      allowClear={false}
                      format={'HH:mm'}
                      disabled
                      value={form.getFieldValue('init_hour')}
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={8}>
              <Form.Item key={'type'} name={'type'} label={'Tipo de aula'}>
                <Select
                  loading={isLoadingServices}
                  options={availableServices}
                  defaultValue={availableServices?.[0]}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item key={'subject'} name={'subject'} label={'Matéria'}>
                <Select
                  loading={isLoadingSubjects}
                  options={availableSubjects}
                  onChange={(value) => {
                    queryClient.invalidateQueries(['teachersListSelect'])
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item key={'grade'} name={'grade'} label={'Turma'}>
                <Select
                  disabled
                  loading={isLoadingGrades}
                  options={availableGrades}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                key={'modality'}
                name={'modality'}
                label={'Modalidade'}
              >
                <Select
                  loading={isLoadingModalities}
                  options={availableModalities}
                  defaultValue={availableModalities?.[0]}
                  onChange={(value) => {
                    filterRooms(value)
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item key={'room'} name={'room'} label={'Local'}>
                <Select loading={isLoadingRooms} options={filteredRooms} />
              </Form.Item>
            </Col>

            <Col span={24}></Col>

            <Col span={12}>
              <Form.Item
                key={'teachers'}
                name={'teachers'}
                label={<Title level={5}>Professor</Title>}
                initialValue={teachers}
                rules={[
                  {
                    required: true,
                    message: 'Selecione pelo menos um professor',
                  },
                ]}
              >
                <Select
                  allowClear={false}
                  loading={isLoadingTeachers}
                  options={availableTeachers}
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) =>
                    option?.label
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                  onChange={(value) => {
                    form.setFieldsValue({
                      teachers: [value],
                    })
                    setTeachers([value])
                  }}
                />
              </Form.Item>
              <Space
                direction={'vertical'}
                style={{
                  display: 'flex',
                  maxHeight: '300px',
                  overflow: 'auto',
                  width: '100%',
                }}
              >
                {teachers?.map((id, index) => (
                  <TeacherCard key={id} id={id} />
                ))}
              </Space>
            </Col>
            <Col span={12}>
              <Form.Item
                key={'students'}
                name={'students'}
                label={
                  <Title level={5}>{`Alunos (${
                    form.getFieldValue('students')?.length
                  }/${
                    availableRooms?.find(
                      (el) => el.value === form.getFieldValue('room'),
                    )?.capacity || 0
                  })`}</Title>
                }
                initialValue={students}
                rules={[
                  { required: true, message: 'Selecione pelo menos um aluno' },
                ]}
              >
                <Select
                  mode={'multiple'}
                  allowClear
                  loading={isLoadingStudents}
                  options={availableStudents}
                  optionFilterProp="children"
                  showSearch
                  filterOption={(input, option) =>
                    option?.label
                      ?.toLowerCase()
                      ?.indexOf(input?.toLowerCase()) >= 0
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label?.toLowerCase() ?? '').localeCompare(
                      optionB?.label?.toLowerCase() ?? '',
                    )
                  }
                  onChange={(value) => {
                    if (
                      value?.length >
                      availableRooms?.find(
                        (el) => el.value === form.getFieldValue('room'),
                      )?.capacity
                    ) {
                      notification.error({
                        message: 'Limite de alunos excedido',
                      })
                      form.setFieldsValue({
                        students,
                      })
                      setStudents(students)
                    } else {
                      form.setFieldsValue({
                        students: value,
                      })
                      setStudents(value)
                    }

                    const id = value[0]

                    setStudentQueryId(id)
                    query.set('student', id || '')
                    window.history.replaceState(
                      {},
                      '',
                      `${window.location.pathname}?${query}`,
                    )
                  }}
                />
              </Form.Item>
              <Space
                direction={'vertical'}
                style={{
                  display: 'flex',
                  maxHeight: '300px',
                  overflow: 'auto',
                  width: '100%',
                }}
              >
                {students?.map((id, index) => (
                  <StudentCard key={id} id={id} index={index} />
                ))}
              </Space>
            </Col>

            <Space
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                padding: '8px 4px',
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                disabled={_.isEmpty(availableTeachers)}
                loading={
                  isLoadingModalities ||
                  isLoadingRooms ||
                  isLoadingServices ||
                  isLoadingSubjects ||
                  isLoadingTeachers ||
                  isLoadingStudents ||
                  students?.length === 0 ||
                  teachers?.length === 0 ||
                  processing
                }
              >
                Agendar aula
              </Button>
            </Space>
          </Row>
        </Form>
      </Card>
    </Space>
  )
}

export default EventCreate
