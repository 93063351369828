import Keycloak from 'keycloak-js'
import { AuthClientInitOptions } from '@react-keycloak/core'

const keycloakCredentials = JSON.parse(import.meta.env.VITE_KEYCLOAK_JSON!)

export const keycloak = new Keycloak({
  url: keycloakCredentials['auth-server-url'],
  realm: keycloakCredentials.realm,
  clientId: keycloakCredentials.resource,
})

export const keycloakConfig: AuthClientInitOptions = {
  onLoad: 'check-sso',
}
