import styled from 'styled-components'
import colors from '~/styles/colors'

const { gray, primary } = colors

export const DashContainer = styled.div`
  //style scroll bar
  .ant-row::-webkit-scrollbar {
    width: 8px;
  }

  .ant-row::-webkit-scrollbar-track {
    background: rgba(245, 247, 250, 0.2);
  }

  .ant-row::-webkit-scrollbar-thumb {
    background: ${primary._000};
  }

  .ant-row::-webkit-scrollbar-thumb:hover {
    background: ${primary._100};
  }
`
