import { InfoCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@tanstack/react-query'
import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  notification,
  Row,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import modal from 'antd/es/modal'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Link from '~/components/Link'
import Table from '~/components/Table'
import { MaskDate, MaskMoney } from '~/helpers/Masks'
import api from '~/services/axios'
import { queryClient } from '~/services/queryClient'

interface PaymentsProps {
  id: string
}

const Payments = ({ id }: PaymentsProps) => {
  const navigate = useNavigate()

  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [isModalDiscountVisible, setIsModalDiscountVisible] = useState(false)
  const [isModalPaymentVisible, setIsModalPaymentVisible] = useState(false)

  const { data: payments, isLoading: isLoadingPayments } = useQuery(
    ['student_payments', id],
    () =>
      api.get(`/api/students/${id}/payments`).then((res) => {
        return res.data.map((item) => ({ ...item, key: item.id }))
      }),
    {
      refetchOnWindowFocus: false,
    }
  )

  const selectedPaymentsTotal = payments
    ?.filter((item) => selectedRowKeys.includes(item.id))
    .reduce((acc, item) => acc + Number(item.amount), 0)

  const [formDiscount] = Form.useForm()
  const discountValue = Form.useWatch('discount', formDiscount)
  const allowanceValue = Form.useWatch('allowance', formDiscount)
  const discountPercentageWatch = Form.useWatch(
    'discount_percent',
    formDiscount
  )
  const allowancePercentageWatch = Form.useWatch(
    'allowance_percent',
    formDiscount
  )

  const { Text } = Typography

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    getCheckboxProps: (record) => ({
      disabled:
        record.status.name === 'Pago' || record.status.name === 'Cancelado', // Column configuration not to be checked
    }),
  }

  const handleDiscount = () => {
    const discount = Number(formDiscount.getFieldsValue().discount || 0)
    const allowance = Number(formDiscount.getFieldsValue().allowance || 0)

    api
      .post(`/api/students/${id}/update-discount`, {
        payments: selectedRowKeys,
        discount,
        allowance,
      })
      .then((res) => {
        notification.success({
          message: 'Desconto aplicado com sucesso',
        })

        queryClient.invalidateQueries(['student_payments', id])
      })
      .catch((_err) => {
        notification.error({
          message: 'Erro ao aplicar desconto',
        })
      })

    setIsModalDiscountVisible(false)
    formDiscount.resetFields()
  }

  const handlePayment = () => {
    api
      .post(`/api/students/${id}/checkout-payments`, {
        payments: selectedRowKeys,
      })
      .then((res) => {
        notification.success({
          message: 'Pagamento realizado com sucesso',
        })

        queryClient.invalidateQueries(['student_payments', id])
        setSelectedRowKeys([])
      })
      .catch((_err) => {
        notification.error({
          message: 'Erro ao realizar pagamento',
        })
      })

    setIsModalPaymentVisible(false)
  }

  const recalculateDiscounts = (changeOn) => {
    const total = selectedPaymentsTotal || 0

    if (changeOn === 'R$') {
      formDiscount.setFieldsValue({
        discount_percent: Number((discountValue / total) * 100 || 0).toFixed(2),
      })
    }

    if (changeOn === '%') {
      formDiscount.setFieldsValue({
        discount: Number(total * (discountPercentageWatch / 100) || 0).toFixed(
          2
        ),
      })
    }
  }

  const recalculatedAllowance = (changeOn) => {
    const total = selectedPaymentsTotal || 0

    if (changeOn === 'R$') {
      formDiscount.setFieldsValue({
        allowance_percent: Number((allowanceValue / total) * 100 || 0).toFixed(
          2
        ),
      })
    }

    if (changeOn === '%') {
      formDiscount.setFieldsValue({
        allowance: Number(
          total * (allowancePercentageWatch / 100) || 0
        ).toFixed(2),
      })
    }
  }

  return (
    <div>
      {Boolean(selectedRowKeys?.length) && (
        <Alert
          type={'warning'}
          description={
            <>
              <p>
                <strong>{selectedRowKeys?.length}</strong> pagamento(s)
                selecionado(s). Totalizando{' '}
                <strong>{MaskMoney(selectedPaymentsTotal)}</strong> em
                pagamentos.
              </p>

              <p>
                Foram aplicados descontos e abatimentos no valor de{' '}
                <strong>
                  {MaskMoney(
                    payments
                      ?.filter((item) => selectedRowKeys.includes(item.id))
                      .reduce((acc, item) => acc + Number(item.discount), 0) +
                    payments
                      ?.filter((item) => selectedRowKeys.includes(item.id))
                      .reduce(
                        (acc, item) => acc + Number(item.allowance),
                        0
                      ) || 0
                  )}
                </strong>
                .
              </p>
              <p>
                Valor a ser pago:{' '}
                <strong>
                  {MaskMoney(
                    selectedPaymentsTotal -
                    (payments
                      ?.filter((item) => selectedRowKeys.includes(item.id))
                      .reduce((acc, item) => acc + Number(item.discount), 0) +
                      payments
                        ?.filter((item) => selectedRowKeys.includes(item.id))
                        .reduce(
                          (acc, item) => acc + Number(item.allowance),
                          0
                        ) || 0)
                  )}
                </strong>
              </p>
            </>
          }
          action={
            <Tooltip
              placement="topRight"
              title={
                <>
                  <p>
                    <strong>Descontos:</strong> Valor a ser descontado do
                    pagamento.
                  </p>
                  <p>
                    <strong>Abatimentos:</strong> Valor a ser abatido do
                    pagamento.
                  </p>
                  <p>
                    Para aplicar descontos ou abatimentos, selecione os
                    pagamentos desejados e clique no botão{' '}
                    <strong>"Conceder Descontos"</strong>.
                  </p>
                  <p>
                    Para confirmar o pagamento, selecione os pagamentos
                    desejados e clique no botão{' '}
                    <strong>"Confirmar Pagamento"</strong>.
                  </p>
                </>
              }
              showArrow={false}
            >
              <InfoCircleOutlined />
            </Tooltip>
          }
        />
      )}

      <Space
        align={'end'}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginBottom: 8,
          marginTop: 8,
        }}
      >
        <Button
          disabled={!selectedRowKeys?.length}
          type="primary"
          loading={isLoadingPayments}
          onClick={() => {
            const discount =
              payments
                ?.filter((item) => selectedRowKeys.includes(item.id))
                .reduce((acc, item) => acc + Number(item.discount), 0) || 0

            const allowance =
              payments
                ?.filter((item) => selectedRowKeys.includes(item.id))
                .reduce((acc, item) => acc + Number(item.allowance), 0) || 0

            setIsModalDiscountVisible(true)
            formDiscount.setFieldsValue({
              discount,
              allowance,
              allowance_percent: Number(
                (allowance / selectedPaymentsTotal) * 100 || 0
              ).toFixed(2),
              discount_percent: Number(
                (discount / selectedPaymentsTotal) * 100 || 0
              ).toFixed(2),
            })
          }}
        >
          Conceder Descontos
        </Button>
        <Button
          disabled={!selectedRowKeys?.length}
          type="primary"
          loading={isLoadingPayments}
          onClick={() => {
            // setIsModalPaymentVisible(true)
            modal.confirm({
              title: 'Confirmar Pagamento',
              onOk: () => handlePayment(),
              okText: 'Confirmar',
              content: (
                <Space direction="vertical" style={{ width: '100%' }}>
                  <Row justify={'end'}>
                    <Text>
                      <Text strong>{selectedRowKeys?.length}</Text> pagamentos
                      selecionados
                    </Text>
                  </Row>
                  <br />
                  <Row justify={'space-between'}>
                    <Text strong>Valor original:</Text>
                    <Text>
                      {MaskMoney(
                        payments
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce((acc, item) => acc + Number(item.amount), 0)
                      )}
                    </Text>
                  </Row>
                  <Row justify={'space-between'}>
                    <Text strong>Desconto:</Text>
                    <Text>
                      {MaskMoney(
                        payments
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce((acc, item) => acc + Number(item.discount), 0)
                      )}{' '}
                      (
                      {MaskMoney(
                        payments
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce(
                            (acc, item) => acc + Number(item.discount),
                            0
                          ) / selectedRowKeys?.length
                      )}{' '}
                      por aula)
                    </Text>
                  </Row>
                  <Row justify={'space-between'}>
                    <Text strong>Abatimento:</Text>
                    <Text>
                      {MaskMoney(
                        payments
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce(
                            (acc, item) => acc + Number(item.allowance),
                            0
                          )
                      )}{' '}
                      (
                      {MaskMoney(
                        payments
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce(
                            (acc, item) => acc + Number(item.allowance),
                            0
                          ) / selectedRowKeys?.length
                      )}{' '}
                      por aula)
                    </Text>
                  </Row>
                  <Row justify={'space-between'}>
                    <Text strong>Valor a pagar:</Text>
                    <Text>
                      {MaskMoney(
                        payments
                          ?.filter((item) => selectedRowKeys.includes(item.id))
                          .reduce(
                            (acc, item) =>
                              acc +
                              Number(item.amount) -
                              Number(item.discount) -
                              Number(item.allowance),
                            0
                          )
                      )}
                    </Text>
                  </Row>
                  <br />
                </Space>
              ),
            })
          }}
        >
          Confirmar Pagamento
        </Button>
      </Space>

      <Table
        loading={isLoadingPayments}
        dataSource={payments}
        rowSelection={rowSelection}
        tableLayout="fixed"
        rowKey="id"
        columns={[
          {
            title: 'Data',
            dataIndex: ['event', 'date'],
            key: 'date',
            render: (date) => MaskDate(date),
          },
          {
            title: 'Aula',
            dataIndex: ['event'],
            key: 'event',
            width: 150,
            render: (item, _) => (
              <Link to={`/portal/events/${item.id}`}>
                <Tag color={item?.status?.color}>{item?.status?.name}</Tag>
              </Link>
            ),
          },
          {
            title: 'Professor',
            dataIndex: ['event', 'teachers', '0', 'user', 'name'],
            key: 'teacher',
            render: (item, _) => (
              <Link
                to={`/portal/teachers/${_.event.teachers[0].id}?view=profile`}
              >
                {item}
              </Link>
            ),
          },
          {
            title: 'Disciplina',
            dataIndex: ['event', 'subject'],
            key: 'subject',
            filters: payments
              ?.map((item) => ({
                text: item?.event?.subject?.name,
                value: item?.event?.subject?.id,
              }))
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.value === item.value)
              ),
            onFilter: (value, record) => record.event.subject.id === value,
            // render tag
            render: (item, _) => <Tag color={item?.color}>{item?.name}</Tag>,
          },
          {
            title: 'Modalidade',
            dataIndex: ['event', 'modality', 'name'],
            key: 'modality',
          },

          {
            title: 'Valor',
            dataIndex: 'amount',
            key: 'amount',
            render: (item, _) => MaskMoney(item),
          },
          {
            title: 'Desconto',
            dataIndex: 'discount',
            key: 'discount',
            render: (item, _) => MaskMoney(item),
          },
          {
            title: 'Abatimento',
            dataIndex: 'allowance',
            key: 'allowance',
            render: (item, _) => MaskMoney(item),
          },
          {
            title: 'Data de Pagamento',
            dataIndex: 'date',
            key: 'date',
            filters: payments
              ?.map((item) => ({
                text: MaskDate(item.date),
                value: MaskDate(item.date),
              }))
              .filter(
                (item, index, self) =>
                  index === self.findIndex((t) => t.value === item.value)
              ),
            onFilter: (value, record) =>
              MaskDate(record.date).indexOf(value) === 0,
            render: (item, _) => MaskDate(item),
          },

          {
            title: 'Status',
            dataIndex: ['status', 'name'],
            key: 'status',
            filters:
              // map payments status unique
              payments
                ?.map((item) => ({
                  text: item.status.name,
                  value: item.status.name,
                }))
                .filter(
                  (item, index, self) =>
                    index === self.findIndex((t) => t.value === item.value)
                ),
            onFilter: (value, record) =>
              record.status.name.indexOf(value) === 0,
          },
        ]}
      />

      <Modal
        title="Descontos e abonos"
        open={isModalDiscountVisible}
        onOk={handleDiscount}
        onCancel={() => {
          setIsModalDiscountVisible(false)
          formDiscount.resetFields()
        }}
      >
        {selectedRowKeys?.length} pagamentos selecionados
        {/*  display total */}
        <p>
          Valor:{' '}
          {MaskMoney(
            payments
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.amount), 0)
          )}
        </p>
        {/* set discount */}
        <p>
          Desconto: {MaskMoney(discountValue || 0)} (
          {MaskMoney((discountValue || 0) / selectedRowKeys?.length)} por aula)
        </p>
        {/* set allowance */}
        <p>
          Abatimento: {MaskMoney(allowanceValue || 0)} (
          {MaskMoney((allowanceValue || 0) / selectedRowKeys?.length)} por aula)
        </p>
        <p>
          Valor a pagar:{' '}
          {MaskMoney(
            payments
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.amount), 0) -
            (discountValue || 0) -
            (allowanceValue || 0)
          )}
        </p>
        <Form
          form={formDiscount}
          name="descounts"
          layout="vertical"
          onFinish={() => { }}
        // wrapperCol={{ span: 10 }}
        >
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Form.Item
                name="discount"
                style={{ marginBottom: '0px' }}
                label="Desconto (R$)"
              >
                <Input
                  placeholder="Desconto (R$)"
                  prefix={'R$'}
                  onBlur={() => recalculateDiscounts('R$')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="discount_percent"
                style={{ marginBottom: '0px' }}
                label="Desconto (%)"
              >
                <Input
                  placeholder="Desconto (%)"
                  suffix={'%'}
                  onBlur={() => recalculateDiscounts('%')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="allowance" label="Abatimento (R$)">
                <Input
                  placeholder="Abatimento (R$)"
                  prefix={'R$'}
                  onBlur={() => recalculatedAllowance('R$')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="allowance_percent" label="Abatimento (%)">
                <Input
                  placeholder="Abatimento (%)"
                  suffix={'%'}
                  onBlur={() => recalculatedAllowance('%')}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        title="Confirmar Pagamento"
        open={isModalPaymentVisible}
        onOk={handlePayment}
        onCancel={() => setIsModalPaymentVisible(false)}
      >
        {selectedRowKeys?.length} pagamentos selecionados
        {/*  display total */}
        <p>
          Valor:{' '}
          {MaskMoney(
            payments
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.amount), 0)
          )}
        </p>
        {/* set discount */}
        <p>
          Desconto aplicado:{' '}
          {MaskMoney(
            payments
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.discount), 0)
          )}{' '}
          (
          {MaskMoney(
            payments
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.discount), 0) /
            selectedRowKeys?.length
          )}{' '}
          por aula)
        </p>
        {/* set allowance */}
        <p>
          Abatimento aplicado:{' '}
          {MaskMoney(
            payments
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.allowance), 0)
          )}{' '}
          (
          {MaskMoney(
            payments
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.allowance), 0) /
            selectedRowKeys?.length
          )}{' '}
          por aula)
        </p>
        <p>
          Valor pago:{' '}
          {MaskMoney(
            payments
              ?.filter((item) => selectedRowKeys.includes(item.id))
              .reduce((acc, item) => acc + Number(item.amount), 0) -
            (discountValue || 0) -
            (allowanceValue || 0)
          )}
        </p>
      </Modal>
    </div>
  )
}

export default Payments
