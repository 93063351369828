import styled from 'styled-components'

import colors from '../../styles/colors'

const { primary, gray } = colors

export const LoadingScreen = styled.div`
  display: flex;
  //background-color: ${primary._000};
  align-content: center;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 400px;
  height: available;

  @-webkit-keyframes building-blocks {
    0%,
    20% {
      opacity: 0;
      -webkit-transform: translateY(-300%);
      transform: translateY(-300%);
    }
    30%,
    70% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    90%,
    100% {
      opacity: 0;
      -webkit-transform: translateY(300%);
      transform: translateY(300%);
    }
  }
  @keyframes building-blocks {
    0%,
    20% {
      opacity: 0;
      -webkit-transform: translateY(-300%);
      transform: translateY(-300%);
    }
    30%,
    70% {
      opacity: 1;
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
    90%,
    100% {
      opacity: 0;
      -webkit-transform: translateY(300%);
      transform: translateY(300%);
    }
  }

  .building-blocks {
    position: relative;
  }

  .building-blocks div {
    height: 20px;
    position: absolute;
    width: 20px;
  }

  .building-blocks div:after {
    -webkit-animation: building-blocks 2.1s ease infinite backwards;
    animation: building-blocks 2.1s ease infinite backwards;
    background: ${gray._200};
    content: '';
    display: block;
    height: 20px;
    width: 20px;
  }

  .building-blocks div:nth-child(1) {
    -webkit-transform: translate(-50%, -50%) translate(60%, 120%);
    transform: translate(-50%, -50%) translate(60%, 120%);
  }

  .building-blocks div:nth-child(2) {
    -webkit-transform: translate(-50%, -50%) translate(-60%, 120%);
    transform: translate(-50%, -50%) translate(-60%, 120%);
  }

  .building-blocks div:nth-child(3) {
    -webkit-transform: translate(-50%, -50%) translate(120%, 0);
    transform: translate(-50%, -50%) translate(120%, 0);
  }

  .building-blocks div:nth-child(4) {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  .building-blocks div:nth-child(5) {
    -webkit-transform: translate(-50%, -50%) translate(-120%, 0);
    transform: translate(-50%, -50%) translate(-120%, 0);
  }

  .building-blocks div:nth-child(6) {
    -webkit-transform: translate(-50%, -50%) translate(60%, -120%);
    transform: translate(-50%, -50%) translate(60%, -120%);
  }

  .building-blocks div:nth-child(7) {
    -webkit-transform: translate(-50%, -50%) translate(-60%, -120%);
    transform: translate(-50%, -50%) translate(-60%, -120%);
  }

  .building-blocks div:nth-child(1):after {
    -webkit-animation-delay: 0.15s;
    animation-delay: 0.15s;
  }

  .building-blocks div:nth-child(2):after {
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
  }

  .building-blocks div:nth-child(3):after {
    -webkit-animation-delay: 0.45s;
    animation-delay: 0.45s;
  }

  .building-blocks div:nth-child(4):after {
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
  }

  .building-blocks div:nth-child(5):after {
    -webkit-animation-delay: 0.75s;
    animation-delay: 0.75s;
  }

  .building-blocks div:nth-child(6):after {
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
  }

  .building-blocks div:nth-child(7):after {
    -webkit-animation-delay: 1.05s;
    animation-delay: 1.05s;
  }
`
