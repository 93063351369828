import {
  Card,
  Col,
  Row,
  Select,
  Space,
  Statistic,
  Tooltip,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import TablePaymentsByMonth from "./components/TablePaymentsByMonth";
import { useQuery } from "@tanstack/react-query";
import api from "~/services/axios";
import useTitleStore from "~/stores/useTitleStore";

const DashPaymentStudents = () => {
  const [year, setYear] = useState(new Date().getFullYear());
  const { setTitle } = useTitleStore();

  const { Text } = Typography;

  const { isLoading, data: user } = useQuery(["me"], () => {
    return api.get("/api/user/me").then((res) => res.data);
  });

  const {
    isLoading: isLoadingFilterYearWhitEvents,
    data: filterYearWhitEvents,
  } = useQuery(["filterYearWhitEvents"], () => {
    return api.get("/api/filters/years-with-events").then((res) => res.data);
  });

  useEffect(() => {
    setTitle({
      title: (
        <>
          {`Olá, ${user?.name}`}{" "}
          <img
            src={"../../assets/img/icons/waving-hand.png"}
            width={24}
            style={{ marginBottom: ".5rem" }}
          />
          <br />
          <Text type="secondary" style={{ fontSize: ".7rem" }}></Text>
        </>
      ),
      avatar: {
        src: user?.picture,
        id: user?.id,
      },
      subTitle: `${
        "São Paulo, " +
        Intl.DateTimeFormat("pt-BR", {
          localeMatcher: "best fit",
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(new Date())
      }`,
      routes: [],
      extra: (
        <Select
          loading={isLoadingFilterYearWhitEvents}
          defaultValue={
            filterYearWhitEvents?.[0]?.value || new Date().getFullYear()
          }
          style={{ width: 100 }}
          onChange={(value) => {
            setYear(value);
          }}
          options={filterYearWhitEvents?.map(({ label, value }) => ({
            label,
            value,
          }))}
        />
      ) as any,
    });
  }, [isLoadingFilterYearWhitEvents]);

  return (
    <Space direction={"vertical"} size={16} style={{ display: "flex" }}>
      {/* <Row gutter={[16, 16]}>
        <Col xs={6} sm={6} md={6} xl={6}>
          <Card loading={true} bordered={false} style={{ height: "100%" }}>
            <Statistic
              title={<Tooltip title="Aulas agendadas">Aulas futuras</Tooltip>}
              value={0}
            />
          </Card>
        </Col>
      </Row> */}

      <Row gutter={[16, 16]}>
        <TablePaymentsByMonth year={year} />
      </Row>
    </Space>
  );
};

export default DashPaymentStudents;
