import React from 'react'
import { Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { PageHeader } from './styles'
import { HeaderProps } from '../../stores/useTitleStore'

const Header = ({
  title = 'Titulo',
  subTitle,
  routes = [],
  isDisabled,
  extra,
  avatar,
  tags,
}: HeaderProps) => {
  const { Text } = Typography
  const navigate = useNavigate()

  function itemRender(routes) {
    return routes?.map((route, index) => {
      const last = routes?.indexOf(route) === routes.length - 1

      return last ? (
        <Text key={index}>{route.breadcrumbName}</Text>
      ) : (
        <Text
          key={index}
          type="secondary"
          className={'breadcrumb-link'}
          onClick={() => navigate(route.path)}
        >
          {route.breadcrumbName + ' / '}
        </Text>
      )
    })
  }

  return (
    <>
      {!isDisabled && (
        <PageHeader
          className="site-page-header"
          backIcon={false}
          onBack={() => null}
          title={title}
          breadcrumb={{ routes }}
          breadcrumbRender={() => itemRender(routes)}
          subTitle={subTitle}
          extra={extra}
          tags={tags}
          avatar={
            avatar?.src
              ? {
                  ...avatar,
                  size: 48,
                }
              : null
          }
        />
      )}
    </>
  )
}

export default Header
