import { MenuDataItem } from "@ant-design/pro-layout";
import React from "react";
import {
  HiOutlineAcademicCap,
  HiOutlineFingerPrint,
  HiOutlineFlag,
  HiOutlineUserGroup,
} from "react-icons/hi";
import { MdOutlineDashboard } from "react-icons/md";
import { TfiAgenda, TfiMoney, TfiSettings } from "react-icons/tfi";
import { FaUserGraduate, FaUserTie } from "react-icons/fa";

const MenuRoutes = [
  {
    icon: <MdOutlineDashboard />,
    name: `Painel`,
    path: "/portal/dashboard",
    tooltip: "Painel",
    is: ["any"],
    children: [
      {
        icon: <HiOutlineFingerPrint />,
        name: `Administrativo`,
        path: "/portal/dashboard/admin",
        tooltip: "Administrativo",
        is: ["admin"],
      },
      {
        icon: <HiOutlineFingerPrint />,
        name: `Secretaria`,
        path: "/portal/dashboard/office",
        tooltip: "Secretaria",
        is: ["secretaria"],
      },
      {
        icon: <HiOutlineFingerPrint />,
        name: `Professor`,
        path: "/portal/dashboard/teacher",
        tooltip: "Professor",
        is: ["professores"],
      },
      {
        icon: <HiOutlineFingerPrint />,
        name: `Pagamentos Alunos`,
        path: "/portal/dashboard/payment-student",
        tooltip: "Pagamentos Alunos",
        is: ["admin", "secretaria"],
      },
      {
        icon: <HiOutlineFingerPrint />,
        name: `Cadastro de Pais e Alunos (2024)`,
        path: "/portal/dashboard/parents",
        tooltip: "Cadastro de Pais e Alunos (2024)",
        is: ["admin", "secretaria"],
      },
    ],
  },
  {
    icon: <TfiAgenda />,
    name: `Agenda`,
    path: "/portal/schedule",
    tooltip: "Agenda",
    is: ["admin", "secretaria"],
  },
  {
    icon: <TfiMoney />,
    name: `Orçamentos`,
    path: "/portal/prices",
    tooltip: "Orçamentos",
    is: ["admin", "secretaria"],
  },
  {
    icon: <FaUserGraduate />,
    name: `Alunos`,
    path: "/portal/students",
    tooltip: "Alunos",
    is: ["admin", "secretaria"],
  },
  {
    icon: <FaUserTie />,
    name: `Professores`,
    path: "/portal/teachers",
    tooltip: "Professores",
    is: ["admin", "secretaria"],
  },
  {
    icon: <TfiSettings />,
    name: `Administrativo`,
    path: "/portal/admin",
    tooltip: "Administrativo",
    children: [
      {
        icon: <HiOutlineUserGroup />,
        name: `Usuários`,
        path: "/portal/admin/users",
        tooltip: "Usuários",
      },
      {
        icon: <HiOutlineFlag />,
        name: `Salas`,
        path: "/portal/admin/rooms",
        tooltip: "Salas",
      },
      {
        icon: <HiOutlineAcademicCap />,
        name: `Matérias`,
        path: "/portal/admin/subjects",
        tooltip: "Matérias",
      },
      {
        icon: <HiOutlineFingerPrint />,
        name: `Perfil`,
        disabled: false,
        path: "/portal/admin/profiles",
        tooltip: "Perfil",
      },
    ],
    disabled: false,
    is: ["admin"],
  },
] as MenuDataItem[];

// recursive function to filter base on profile and permissions
export const filterMenu = (menu: MenuDataItem[], keycloak: any) => {
  const permissions = keycloak?.resourceAccess?.contemporaneo?.roles;
  const profile = keycloak?.tokenParsed?.profiles;

  // recursive function to filter base on profile and permissions
  return menu.filter((item) => {
    if (item.children) {
      item.children = filterMenu(item.children, keycloak);
    }

    if (item.is === undefined) {
      return true;
    }

    if (item.is) {
      // if have permissions
      if (item.is.includes("any")) {
        return true;
      }

      // if is[] have profile[]
      if (item.is.some((x) => profile?.includes(x))) {
        return true;
      }

      return false;
    }

    // if have permissions
    if (item.have) {
      // if have permissions
      if (item.have.includes("any")) {
        return true;
      }

      // if have permissions
      if (item.have.some((permission) => permissions?.includes(permission))) {
        return true;
      }

      return false;
    }

    return true;
  });
};

export default MenuRoutes;
