import { useQuery } from "@tanstack/react-query";
import { Card, Col, Row, Space, Typography } from "antd";
import React, { useEffect } from "react";
import api from "~/services/axios";
import useTitleStore from "~/stores/useTitleStore";

const DashParents = () => {
  const { setTitle } = useTitleStore();

  const { isLoading, data: user } = useQuery(["me"], () => {
    return api.get("/api/user/me").then((res) => res.data);
  });

  const { Text } = Typography;

  useEffect(() => {
    setTitle({
      title: (
        <>
          {`Olá, ${user?.name}`}{" "}
          <img
            src={"../../assets/img/icons/waving-hand.png"}
            width={24}
            style={{ marginBottom: ".5rem" }}
          />
          <br />
          <Text type="secondary" style={{ fontSize: ".7rem" }}></Text>
        </>
      ),
      subTitle: `${
        "São Paulo, " +
        Intl.DateTimeFormat("pt-BR", {
          localeMatcher: "best fit",
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(new Date())
      }`,
      avatar: {
        src: user?.picture,
        id: user?.id,
      },
      extra: undefined,
    });
  }, []);

  return (
    <Space direction={"vertical"} size={0} style={{ display: "flex" }}>
      <Row gutter={[16, 0]}>
        <Col sm={24} md={24} xl={24}>
          <Card
            bordered={false}
            style={{ height: "100%" }}
            bodyStyle={{ padding: 0, borderRadius: "10px", overflow: "auto" }}
          >
            <iframe
              style={{ width: "100%", height: "100vh", border: "none" }}
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vT4fNBj7UiKeFPUtUANiEsFOS6UTY2s67vi01tm1I2qONY_XNr2vVEdgG_GbCtnz0fAHGJjQTF1Cqa1/pubhtml?gid=204141126&amp;single=true&amp;widget=true&amp;headers=false"
            ></iframe>
          </Card>
        </Col>
      </Row>
    </Space>
  );
};

export default DashParents;
