import React from 'react'

import { LoadingScreen } from './styles'
import useThemeStore from '~/stores/useThemeStore'

const Splash = () => {
  const { theme } = useThemeStore()

  return (
    <LoadingScreen
      style={{
        backgroundColor: theme === 'dark' ? '#31353F' : '#247346',
      }}
    >
      <div className="coffee">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingScreen>
  )
}

export default Splash
