import { Pie, WordCloud } from '@ant-design/charts'
import { useQuery } from '@tanstack/react-query'
import { Col, Typography } from 'antd'
import React from 'react'
import api from '~/services/axios'

interface DevelopmentProps {
  id: string
}

const Development = ({ id }: DevelopmentProps) => {
  const { Text, Title } = Typography

  const { data, isLoading } = useQuery(['student_development', id], () =>
    api.get(`/api/students/${id}/development`).then((res) => res.data),
  )

  const dataCloudWords =
    isLoading || !data?.filter((item) => item.question === 'Mapa de palavras')
      ? []
      : Object?.entries(
          data?.filter((item) => item.question === 'Mapa de palavras')[0]
            ?.answers,
        ).map(([key, value]) => ({ name: key, value }))

  const dataStudentDifficulties =
    isLoading ||
    !data?.filter(
      (item) => item.question === 'O aluno apresentou alguma dificuldade?',
    )[0]?.answers
      ? []
      : Object?.entries(
          data
            ?.filter(
              (item) =>
                item.question === 'O aluno apresentou alguma dificuldade?',
            )[0]
            ?.answers?.reduce((acc, curr) => {
              acc[curr] = (acc[curr] || 0) + 1
              return acc
            }, {}),
        ).map(([key, value]) => ({ type: key, value }))

  const dataStudentPreviousPreparation =
    isLoading ||
    !data?.filter((item) => item.question === 'O aluno fez preparo prévio?')[0]
      ?.answers
      ? []
      : Object?.entries(
          data
            ?.filter(
              (item) => item.question === 'O aluno fez preparo prévio?',
            )[0]
            ?.answers?.reduce((acc, curr) => {
              acc[curr] = (acc[curr] || 0) + 1
              return acc
            }, {}),
        ).map(([key, value]) => ({ type: key, value }))

  const dataStudentHomeWork =
    isLoading ||
    !data?.filter(
      (item) =>
        item.question ===
        'No final da aula foi passado a lição de casa por você?',
    )[0]?.answers
      ? []
      : Object?.entries(
          data
            ?.filter(
              (item) =>
                item.question ===
                'No final da aula foi passado a lição de casa por você?',
            )[0]
            ?.answers?.reduce((acc, curr) => {
              acc[curr] = (acc[curr] || 0) + 1
              return acc
            }, {}),
        ).map(([key, value]) => ({ type: key, value }))

  const cloudWords = {
    data: dataCloudWords,
    wordField: 'name',
    weightField: 'value',
    colorField: 'name',
    wordStyle: {
      fontFamily: 'Verdana',
      fontSize: [8, 32],
      rotation: 0,
    },
  }

  const studentDifficulties = {
    appendPadding: 10,
    data: dataStudentDifficulties,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  }

  const studentPreviousPreparation = {
    appendPadding: 10,
    data: dataStudentPreviousPreparation,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  }

  const studentHomeWork = {
    appendPadding: 10,
    data: dataStudentHomeWork,
    angleField: 'value',
    colorField: 'type',
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
    ],
  }

  return (
    <>
      <Col span={12} style={{ width: '100%' }}>
        <Title level={5}>O aluno apresentou alguma dificuldade?</Title>
        <Pie loading={isLoading} {...studentDifficulties} />
      </Col>

      <Col span={12} style={{ width: '100%' }}>
        <Title level={5}>O aluno fez preparo prévio?</Title>
        <Pie loading={isLoading} {...studentPreviousPreparation} />
      </Col>

      {/* <Col span={8}> */}
      {/*  <Title level={5}> */}
      {/*    No final da aula foi passado a lição de casa por você? */}
      {/*  </Title> */}
      {/*  <Pie loading={isLoading} {...studentHomeWork} /> */}
      {/* </Col> */}

      <Col span={24}>
        <Title level={5}>
          Mapa de palavras atribuídas ao aluno pelos professores
        </Title>
        <WordCloud loading={isLoading} {...cloudWords} />
      </Col>
    </>
  )
}

export default Development
