import {
  Column,
  ColumnConfig,
  Line,
  measureTextWidth,
  Pie,
} from '@ant-design/charts'
import { useQuery } from '@tanstack/react-query'
import { Card, Col, notification, Row, Statistic, Tag, Typography } from 'antd'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import Loading from '~/components/Loading'
import api from '~/services/axios'
// import useThemeStore from '~/stores/useThemeStore'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import Table from '~/components/Table'
import { MaskDateWithTime } from '~/helpers/Masks'
import useTitleStore from '~/stores/useTitleStore'
import colors from '~/styles/colors'
import CardUser from '../../../components/CardUser'

interface IStudent {
  total: number
}

interface ITeacher {
  total: number
  active: number
}

interface IEvents {
  total: number
  upcoming: number
}

const DashAdmin = () => {
  const { setTitle } = useTitleStore()

  const [activeTabKeyEvents, setActiveTabKeyEvents] = useState<string>('tab1')
  const [activeTabKeyPayments, setActiveTabKeyPayments] =
    useState<string>('tab1')

  const [logsPage, setLogsPage] = useState({ page: 1, pageSize: 10 })

  const { Text, Title } = Typography

  const { data: user } = useQuery(['me'], () => {
    return api
      .get('/api/user/me')
      .then((res) => res.data)
      .catch((err) => { })
  })

  useEffect(() => {
    setTitle({
      title: (
        <>
          <Title level={3} style={{ color: colors.gray._000, marginBottom: 0 }}>
            {`Olá, `}
            <span style={{ fontWeight: 'lighter' }}>{user?.name}</span>
          </Title>
        </>
      ),
      subTitle: '',
      avatar: {
        src: user?.picture,
        id: user?.id,
      },
      extra: [
        // <Select
        //   key="select"
        //   defaultValue="6-months"
        //   style={{ width: 120 }}
        //   options={[
        //     { label: '6 Meses', value: '6-months' },
        //     { label: 'Ultimo mês', value: 'last-month' },
        //     { label: '6 Semanas', value: '6-weeks' },
        //     { label: '3 Meses', value: '3-months' },
        //     { label: '1 ano', value: 'last-year' },
        //     { label: 'Futuro', value: 'future' },
        //     { label: 'Tudo', value: 'all' },
        //   ]}
        // />,
      ],
      isDisabled: false,
      routes: [],
    })
  }, [])

  const { isLoading: isLoadingStudents, data: studentStats } =
    useQuery<IStudent>(['studentStats'], () => {
      return api
        .get('/api/statistics/students')
        .then((res) => res.data)
        .catch((err) => {
          notification.error({
            message: 'Erro ao carregar dados de estatísticas de alunos',
          })
        })
    })

  const { isLoading: isLoadingTeachers, data: teacherStats } =
    useQuery<ITeacher>(['teacherStats'], () => {
      return api
        .get('/api/statistics/teachers')
        .then((res) => res.data)
        .catch((err) => {
          notification.error({
            message: 'Erro ao carregar dados de estatísticas de professores',
          })
        })
    })

  const { isLoading: isLoadingEvents, data: eventStats } = useQuery<IEvents>(
    ['eventStats'],
    () => {
      return api
        .get('/api/statistics/events')
        .then((res) => res.data)
        .catch((err) => {
          notification.error({
            message: 'Erro ao carregar dados de estatísticas das aulas',
          })
        })
    }
  )

  const { isLoading: isLoadingByTeacher, data: byTeacher } =
    useQuery<ColumnConfig>(['byTeacher'], () => {
      return api
        .get('/api/statistics/events/by-teachers')
        .then((res) => {
          return {
            data: res.data,
            xField: 'teacher',
            yField: 'total',
            seriesField: 'teacher',
            colorField: 'teacher',
            color: ['#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16', '#E8684A'],
            legend: {
              position: 'bottom-left',
            },
            label: {
              position: 'middle',
              style: {
                fill: '#FFFFFF',
                opacity: 0.6,
              },
            },
            xAxis: {
              label: {
                autoHide: true,
                autoRotate: false,
              },
            },
            meta: {
              teacher: {
                alias: 'Professor',
              },
              total: {
                alias: 'Total de aulas',
              },
            },
          }
        })
        .catch((err) => {
          notification.error({
            message: 'Erro ao carregar dados de estatísticas de professores',
          })
        })
    })

  const { isLoading: isLoadingByStudent, data: byStudent } =
    useQuery<ColumnConfig>(['byStudent'], () => {
      return api
        .get('/api/statistics/events/by-students')
        .then((res) => {
          return {
            data: res.data,
            xField: 'student',
            yField: 'total',
            seriesField: 'student',
            colorField: 'student',
            color: ['#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16', '#E8684A'],
            legend: {
              position: 'bottom-left',
            },
            label: {
              position: 'middle',
              style: {
                fill: '#FFFFFF',
                opacity: 0.6,
              },
            },
            xAxis: {
              label: {
                // autoHide: true,
                autoRotate: false,
              },
            },
            meta: {
              student: {
                alias: 'Aluno',
              },
              total: {
                alias: 'Total de aulas',
              },
            },
          }
        })
        .catch((err) => {
          notification.error({
            message: 'Erro ao carregar dados de estatísticas de alunos',
          })
        })
    })

  const { isLoading: isLoadingBySubjects, data: bySubjects } =
    useQuery<ColumnConfig>(['bySubjects'], () => {
      return api
        .get('/api/statistics/events/by-subjects')
        .then((res) => {
          return {
            data: res.data,
            xField: 'subject',
            yField: 'total',
            seriesField: 'subject',
            colorField: 'subject',
            color: res.data.map((item) => item.color),
            legend: {
              position: 'bottom-left',
            },
            label: {
              position: 'middle',
              style: {
                fill: '#FFFFFF',
                opacity: 0.6,
              },
            },
            xAxis: {
              label: {
                autoHide: true,
                autoRotate: false,
              },
            },
            meta: {
              subject: {
                alias: 'Matéria',
              },
              total: {
                alias: 'Total de aulas',
              },
            },
          }
        })
        .catch((err) => {
          notification.error({
            message: 'Erro ao carregar dados de estatísticas de matérias',
          })
        })
    })

  const { isLoading: isLoadingByModalities, data: byModalities } =
    useQuery<ColumnConfig>(['byModalities'], () => {
      return api
        .get('/api/statistics/events/by-modalities')
        .then((res) => {
          return {
            data: res.data,
            xField: 'modality',
            yField: 'total',
            seriesField: 'modality',
            colorField: 'modality',
            color: ['#5B8FF9', '#5AD8A6', '#5D7092', '#F6BD16', '#E8684A'],
            legend: {
              position: 'bottom-left',
            },
            label: {
              position: 'middle',
              style: {
                fill: '#FFFFFF',
                opacity: 0.6,
              },
            },
            xAxis: {
              label: {
                autoHide: true,
                autoRotate: false,
              },
            },
            meta: {
              modality: {
                alias: 'Modalidade',
              },
              total: {
                alias: 'Total de aulas',
              },
            },
          }
        })
        .catch((err) => {
          notification.error({
            message: 'Erro ao carregar dados de estatísticas de modalidades',
          })
        })
    })

  const { isLoading: isLoadingByGrades, data: byGrades } =
    useQuery<ColumnConfig>(['byGrades'], () => {
      return api
        .get('/api/statistics/events/by-grades')
        .then((res) => {
          return {
            data: res.data,
            xField: 'grade',
            yField: 'total',
            seriesField: 'grade',
            colorField: 'grade',
            color: res.data.map((item) => item.color),
            legend: {
              position: 'bottom-left',
            },
            label: {
              position: 'middle',
              style: {
                fill: '#FFFFFF',
                opacity: 0.6,
              },
            },
            xAxis: {
              label: {
                autoHide: true,
                autoRotate: false,
              },
            },
            meta: {
              grade: {
                alias: 'Modalidade',
              },
              total: {
                alias: 'Total de aulas',
              },
            },
          }
        })
        .catch((err) => {
          notification.error({
            message: 'Erro ao carregar dados de estatísticas de turmas',
          })
        })
    })

  function renderStatistic(containerWidth, text, style) {
    const { width: textWidth, height: textHeight } = measureTextWidth(
      text,
      style
    )
    const R = containerWidth / 2 // r^2 = (w / 2)^2 + (h - offsetY)^2

    let scale = 1

    if (containerWidth < textWidth) {
      scale = Math.min(
        Math.sqrt(
          Math.abs(
            Math.pow(R, 2) /
            (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2))
          )
        ),
        1
      )
    }

    const textStyleStr = `width:${containerWidth}px;`
    return `<div style='${textStyleStr};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'
      };'>${text}</div>`
  }

  const { isLoading: isLoadingPiePayments, data: piePayments } =
    useQuery<ColumnConfig>(['piePayments'], () => {
      return api
        .get('/api/statistics/payments')
        .then((res) => {
          const dictionary = {
            open: 'Pag. aberto',
            paid: 'Pago',
            canceled: 'Cancelado',
            future: 'Pag. futuro',
            total: 'Total',
          }

          const data = Object.keys(res.data)
            .map((key) => ({
              type: dictionary[key],
              value: res.data[key],
            }))
            .filter((item) => item.type !== 'Total')

          return {
            data,
            appendPadding: 10,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.64,
            meta: {
              value: {
                formatter: (v) => `${v}`,
              },
            },
            label: {
              type: 'inner',
              offset: '-50%',
              style: {
                textAlign: 'center',
              },
              autoRotate: false,
              content: '{value}',
            },
            statistic: {
              title: {
                offsetY: -4,
                customHtml: (container, view, datum) => {
                  const { width, height } = container.getBoundingClientRect()
                  const d = Math.sqrt(
                    Math.pow(width / 2, 2) + Math.pow(height / 2, 2)
                  )
                  const text = datum ? datum.type : 'Total'
                  return renderStatistic(d, text, {
                    fontSize: 28,
                  })
                },
              },
              content: {
                offsetY: 4,
                style: {
                  fontSize: '32px',
                },
                customHtml: (container, view, datum, data) => {
                  const { width } = container.getBoundingClientRect()
                  const text = datum
                    ? `${datum.value}`
                    : `${data.reduce((r, d) => r + d.value, 0)}`
                  return renderStatistic(width, text, {
                    fontSize: 32,
                  })
                },
              },
            },
            interactions: [
              {
                type: 'element-selected',
              },
              {
                type: 'element-active',
              },
              {
                type: 'pie-statistic-active',
              },
            ],
          }
        })
        .catch((err) => {
          notification.error({
            message: 'Erro ao carregar dados de estatísticas de pagamentos',
          })
        })
    })

  const { isLoading: isLoadingByPaymentsDiscounts, data: byPaymentsDiscounts } =
    useQuery(['byPaymentsDiscounts'], () => {
      return api
        .get('/api/statistics/payments/payments-discounts-monthly')
        .then((res) => {
          const dictionary = {
            amount: 'Total previsto',
            discount: 'Desconto',
            allowance: 'Abono',
            total_discounts: 'Descontos + Abono',
            total: 'Total real',
          }

          const data = res.data.map((item, index) => {
            return Object.keys(item)
              .map((key) => {
                if (
                  key === 'amount' ||
                  key === 'discount' ||
                  key === 'allowance' ||
                  key === 'total_discounts' ||
                  key === 'total'
                ) {
                  return {
                    type: dictionary[key],
                    value: parseFloat(item[key]),
                    date: item.date,
                  }
                }
              })
              .filter((item) => item !== undefined && item.date !== '/')
          })

          return {
            data: _.flatten(data),
            xField: 'date',
            yField: 'value',
            seriesField: 'type',
            yAxis: {
              range: [0, 0.9],
              label: {
                formatter: (v) =>
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(v),
              },
            },
            point: {
              size: 4,
            },
            xAxis: {
              range: [0, 1],
              label: {
                autoHide: true,
                autoRotate: false,
              },
            },
            slider: {
              start: 0,
              end: 0.5,
            },
            tooltip: {
              formatter: (datum) => {
                return {
                  name: datum.type,
                  value: new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(datum.value),
                }
              },
            },
            lineStyle: ({ type }) => {
              if (type === 'Total previsto') {
                return {
                  lineDash: [4, 4],
                  opacity: 0.8,
                }
              }
              if (type === 'Descontos + Abono') {
                return {
                  lineDash: [4, 4],
                  opacity: 0.8,
                }
              }
            },
          }
        })
        .catch((err) => {
          notification.error({
            message:
              'Erro ao carregar dados de estatísticas de pagamentos alunos',
          })
        })
    })

  const { isLoading: isLoadingCashFlow, data: byCashFlow } = useQuery(
    ['byCashFlow'],
    () => {
      return api
        .get('/api/statistics/payments/cash-flow-in-out-monthly')
        .then((res) => {
          const dictionary = {
            income_predict: 'Entrada prevista',
            income_real: 'Entrada real',
            outcome_predict: 'Saída prevista',
            outcome_real: 'Saída real',
            balance_predict: 'Saldo previsto',
            balance_real: 'Saldo real',
          }

          const data = res.data.map((item, index) => {
            return Object.keys(item)
              .map((key) => {
                if (dictionary[key]) {
                  return {
                    type: dictionary[key],
                    value: parseFloat(item[key]),
                    date: item.date,
                  }
                }
              })
              .filter((item) => item !== undefined && item.date !== '/')
          })

          return {
            data: _.flatten(data),
            xField: 'date',
            yField: 'value',
            seriesField: 'type',
            yAxis: {
              range: [0, 0.9],
              label: {
                formatter: (v) =>
                  new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(v),
              },
            },
            point: {
              size: 4,
            },
            xAxis: {
              range: [0, 1],
              label: {
                autoRotate: false,
              },
            },
            slider: {
              start: 0,
              end: 0.5,
            },
            tooltip: {
              formatter: (datum) => {
                return {
                  name: datum.type,
                  value: new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(datum.value),
                }
              },
            },
            lineStyle: ({ type }) => {
              if (type === 'Entrada prevista') {
                return {
                  lineDash: [4, 4],
                  opacity: 0.8,
                }
              }
              if (type === 'Saída prevista') {
                return {
                  lineDash: [4, 4],
                  opacity: 0.8,
                }
              }
              if (type === 'Saldo previsto') {
                return {
                  lineDash: [4, 4],
                  opacity: 0.8,
                }
              }
            },
          }
        })
        .catch((err) => {
          notification.error({
            message:
              'Erro ao carregar dados de estatísticas de pagamentos alunos',
          })
        })
    }
  )

  const { isLoading: isLoadingLogs, data: logs } = useQuery(
    ['logs', logsPage?.page, logsPage?.pageSize],
    () => {
      console.log('logsPage', logsPage)
      return api
        .get('/api/logs', {
          params: {
            page: logsPage?.page || 1,
            pageSize: logsPage?.pageSize || 10,
          },
        })
        .then((res) => {
          return res.data
        })
        .catch((err) => {
          notification.error({
            message: 'Erro ao carregar dados de logs',
          })
        })
    }
  )

  const tabListEvents = [
    {
      key: 'tab1',
      tab: 'Professores',
    },
    {
      key: 'tab5',
      tab: 'Alunos',
    },
    {
      key: 'tab2',
      tab: 'Matérias',
    },
    {
      key: 'tab3',
      tab: 'Modalidades',
    },
    {
      key: 'tab4',
      tab: 'Turmas',
    },
  ]

  const tabListPaymetns = [
    {
      key: 'tab1',
      tab: 'Pagamentos',
    },
    {
      key: 'tab2',
      tab: 'Pagamentos alunos',
    },
    {
      key: 'tab3',
      tab: 'Fluxo de caixa',
    },
  ]

  const contentListEvents: Record<string, React.ReactNode> = {
    tab1: isLoadingByTeacher ? <Loading /> : <Column {...byTeacher} />,
    tab2: isLoadingBySubjects ? <Loading /> : <Column {...bySubjects} />,
    tab3: isLoadingByModalities ? <Loading /> : <Column {...byModalities} />,
    tab4: isLoadingByGrades ? <Loading /> : <Column {...byGrades} />,
    tab5: isLoadingByStudent ? <Loading /> : <Column {...byStudent} />,
  }

  const contentListPayments: Record<string, React.ReactNode> = {
    tab1: isLoadingPiePayments ? <Loading /> : <Pie {...piePayments} />,
    tab2: isLoadingByPaymentsDiscounts ? (
      <Loading />
    ) : (
      <Line {...byPaymentsDiscounts} />
    ),
    tab3: isLoadingCashFlow ? <Loading /> : <Line {...byCashFlow} />,
  }

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <Card bordered={false} style={{ height: '100%' }}>
                <Statistic
                  loading={isLoadingStudents}
                  title="Alunos"
                  value={studentStats?.total}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <Card bordered={false} style={{ height: '100%' }}>
                <Statistic
                  loading={isLoadingTeachers}
                  title="Professores ( Ativos / Total )"
                  value={`${teacherStats?.active} / ${teacherStats?.total}`}
                />
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <Card bordered={false} style={{ height: '100%' }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Statistic
                      loading={isLoadingEvents}
                      title="Aulas totais"
                      value={eventStats?.total}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
            <Col xs={12} sm={12} md={6} lg={6} xl={6} xxl={6}>
              <Card bordered={false} style={{ height: '100%' }}>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <Statistic
                      loading={isLoadingEvents}
                      title="Aulas agendadas"
                      value={eventStats?.upcoming}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col sm={24} md={12}>
              <Card
                bordered={false}
                title="Pagamentos por"
                tabList={tabListPaymetns}
                activeTabKey={activeTabKeyPayments}
                onTabChange={(key) => setActiveTabKeyPayments(key)}
                style={{ height: '100%' }}
              >
                {contentListPayments[activeTabKeyPayments]}
              </Card>
            </Col>
            <Col sm={24} md={12}>
              <Card
                bordered={false}
                title="Aulas por"
                tabList={tabListEvents}
                activeTabKey={activeTabKeyEvents}
                onTabChange={(key) => setActiveTabKeyEvents(key)}
                style={{ height: '100%' }}
              >
                {contentListEvents[activeTabKeyEvents]}
              </Card>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Card
                title="Log - Ultimas ações"
                bordered={false}
                bodyStyle={{ padding: 0 }}
              >
                <Table
                  dataSource={logs?.data}
                  loading={isLoadingLogs}
                  rowKey="id"
                  columns={[
                    {
                      title: 'Data',
                      dataIndex: 'created_at',
                      key: 'created_at',
                      render: (value) => {
                        return MaskDateWithTime(value)
                      },
                      width: 150,
                    },
                    {
                      title: 'Usuário',
                      dataIndex: 'user_id',
                      key: 'user_id',
                      render: (value) => {
                        return <CardUser id={value} />
                      },
                    },
                    {
                      title: 'Ação',
                      dataIndex: 'type',
                      key: 'type',
                      render: (value) => {
                        return <Tag>{value}</Tag>
                      },
                    },
                    {
                      title: 'Descrição',
                      dataIndex: 'message',
                      key: 'message',
                      render: (value) => {
                        return (
                          <ReactMarkdown
                            children={value.toString()}
                            remarkPlugins={[remarkGfm]}
                          />
                        )
                      },
                    },
                  ]}
                  pagination={{
                    pageSize: logs?.per_page || 10,
                    total: logs?.total || 0,
                    current: logs?.current_page || 1,
                    onChange: (page, pageSize) => {
                      setLogsPage({ page, pageSize })
                    },
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

export default DashAdmin
