import { useQuery } from '@tanstack/react-query'
import {
  Badge,
  Button,
  Calendar,
  Card,
  Col,
  Row,
  Space,
  Statistic,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useMemo, useState } from 'react'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'
import { useLocation, useNavigate } from 'react-router-dom'
import Next5DaysCalendar from '~/components/Next5DaysCalendar'
import Table from '~/components/Table'
import { MaskDate } from '~/helpers/Masks'
import api from '~/services/axios'
import useTitleStore from '~/stores/useTitleStore'

const DashTeachers = () => {
  const navigate = useNavigate()
  const { setTitle } = useTitleStore()

  const [currentTab, setCurrentTab] = useState('day')
  const { Text, Title } = Typography

  const query = new URLSearchParams(useLocation().search)
  const [currentDate, setCurrentDate] = useState(() =>
    query.get('date') ? dayjs(query.get('date')) : dayjs()
  )

  useEffect(() => {
    query.set('date', currentDate.format('YYYY-MM-DD'))
    navigate({ search: query.toString() }, { replace: true })
  }, [currentDate])

  useEffect(() => {
    setTitle({
      title: (
        <>
          {`Olá, ${user?.name}`}{' '}
          <img
            src={'../../assets/img/icons/waving-hand.png'}
            width={24}
            style={{ marginBottom: '.5rem' }}
          />
          <br />
          <Text type="secondary" style={{ fontSize: '.7rem' }}></Text>
        </>
      ),
      avatar: {
        src: user?.picture,
        id: user?.id,
      },
      subTitle: `${'São Paulo, ' +
        Intl.DateTimeFormat('pt-BR', {
          localeMatcher: 'best fit',
          // weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }).format(new Date())
        }`,
      routes: [],
    })
  }, [])

  const { isLoading, data: user } = useQuery(['me'], () => {
    return api
      .get('/api/user/me')
      .then((res) => res.data)
      .catch((err) => { })
  })

  const { data: datesEvents } = useQuery(
    ['teachers', user?.teacher_id, 'dates-events'],
    () => {
      return api
        .get(`/api/teachers/${user.teacher_id}/dates-with-events`)
        .then((res) => res.data.dates)
        .catch((err) => { })
    },
    {
      enabled: !isLoading,
    }
  )

  const { isLoading: isLoadingStats, data: stats } = useQuery(
    ['teachers', user?.teacher_id, 'stats'],
    () => {
      return api
        .get(`/api/teachers/${user.teacher_id}/stats`)
        .then((res) => res.data)
        .catch((err) => { })
    },
    {
      enabled: !isLoading,
    }
  )

  const { isLoading: isLoadingEventsToday, data: eventsToday } = useQuery(
    [
      'listAllEventsToday',
      currentDate?.format('YYYY-MM-DD') || '',
      user.teacher_id,
    ],
    () => {
      return api
        .get(`/api/teachers/${user.teacher_id}/events`, {
          params: {
            today: true,
            date: currentDate ? currentDate?.format('YYYY-MM-DD') : '',
            datailed: true,
          },
        })
        .then((res) => {
          const data = res.data.filter(
            (event) => event?.status?.name !== 'Cancelado'
          )

          return data
        })
        .catch((err) => { })
    }
  )

  const { isLoading: isLoadingEventsAvaliations, data: eventsAvaliations } =
    useQuery(['listAllEventsAvaliations', user.teacher_id], () => {
      return api
        .get(`/api/teachers/${user.teacher_id}/events`, {
          params: {
            status: 'Avaliação: Pendente',
            datailed: true,
          },
        })
        .then((res) => res.data)
        .catch((err) => { })
    })

  const { isLoading: isLoadingEventsRequests, data: eventsRequests } = useQuery(
    ['listAllEventsRequests', user.teacher_id],
    () => {
      return api
        .get(`/api/teachers/${user.teacher_id}/events`, {
          params: {
            status: 'Solicitado',
            datailed: true,
          },
        })
        .then((res) => res.data)
        .catch((err) => { })
    }
  )

  const { isLoading: isLoadingEventsConfirmations, data: eventsConfirmations } =
    useQuery(['listAllEventsConfirmations', user.teacher_id], () => {
      return api
        .get(`/api/teachers/${user.teacher_id}/events`, {
          params: {
            status: 'Confirmado',
            datailed: true,
          },
        })
        .then((res) => res.data)
        .catch((err) => { })
    })

  const { isLoading: isLoadingEventsFuture, data: eventsFuture } = useQuery(
    ['listAllFutureEvents', user.teacher_id],
    () => {
      return api
        .get(`/api/teachers/${user.teacher_id}/events`, {
          params: {
            future: true,
            datailed: true,
          },
        })
        .then((res) => {
          return res.data.filter((event) => event?.status?.name !== 'Cancelado')
        })
        .catch((err) => { })
    }
  )

  const currentDataTable = useMemo(() => {
    switch (currentTab) {
      case 'day':
        return eventsToday
      case 'future':
        return eventsFuture
      case 'pending-avaliations':
        return eventsAvaliations
      case 'awaiting-availability':
        return eventsRequests
      case 'awaiting-confirmation':
        return eventsConfirmations
      default:
        return eventsToday
    }
  }, [currentTab, eventsToday, eventsFuture, eventsAvaliations, eventsRequests])

  const currentDataTableLoading = useMemo(() => {
    switch (currentTab) {
      case 'day':
        return isLoadingEventsToday
      case 'future':
        return isLoadingEventsFuture
      case 'pending-avaliations':
        return isLoadingEventsAvaliations
      case 'awaiting-availability':
        return isLoadingEventsRequests
      case 'awaiting-confirmation':
        return isLoadingEventsConfirmations
      default:
        return isLoadingEventsToday
    }
  }, [
    currentTab,
    isLoadingEventsToday,
    isLoadingEventsFuture,
    isLoadingEventsAvaliations,
    isLoadingEventsRequests,
  ])

  return (
    <Space direction={'vertical'} size={16} style={{ display: 'flex' }}>
      <Row gutter={[16, 16]}>
        <Col sm={24} md={18} xl={18}>
          <Row gutter={[16, 16]}>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Card
                loading={isLoadingStats}
                bordered={false}
                style={{ height: '100%' }}
              >
                <Statistic
                  title={
                    <Tooltip title="Aulas agendadas para você hoje">
                      Aulas hoje
                    </Tooltip>
                  }
                  value={stats?.total_events_today}
                />
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Card
                loading={isLoadingStats}
                bordered={false}
                style={{ height: '100%' }}
              >
                <Statistic
                  title={
                    <Tooltip title="Avaliações de desempenho pendentes de resposta">
                      Avaliações pendentes
                    </Tooltip>
                  }
                  value={
                    new Intl.NumberFormat('pt-BR', {}).format(
                      stats?.total_events_to_be_evaluated
                    ) || 0
                  }
                // suffix={`/
                //   ${Number(stats?.total_events || 0) -
                //   Number(stats?.total_events_canceled || 0)
                //   }
                // `}

                // suffix={`/ (${new Intl.NumberFormat('pt-BR', {}).format(
                //   stats?.total_events || 0
                // )} - ${new Intl.NumberFormat('pt-BR', {}).format(
                //   stats?.total_events_canceled || 0
                // )})`}
                />
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Card
                loading={isLoadingStats}
                bordered={false}
                style={{ height: '100%' }}
              >
                <Statistic
                  title="Total de alunos"
                  value={stats?.total_students}
                />
              </Card>
            </Col>
            <Col xs={6} sm={6} md={6} xl={6}>
              <Card
                loading={isLoadingStats}
                bordered={false}
                style={{ height: '100%' }}
              >
                <Statistic
                  title={
                    <Tooltip title="Aulas contadas a partir do dia 1 do mês atual">
                      Total de horas aula
                    </Tooltip>
                  }
                  value={stats?.total_hours_current_month}
                />
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24}>
              <Card
                tabList={[
                  { tab: 'Aulas do dia', key: 'day' },
                  { tab: 'Próximas aulas', key: 'future' },
                  {
                    tab: (
                      <Badge
                        count={eventsRequests?.length || 0}
                        offset={[10, -5]}
                      >
                        <span>Confirmar disponibilidade</span>
                      </Badge>
                    ),
                    key: 'awaiting-availability',
                  },
                  {
                    tab: (
                      <Badge
                        count={eventsConfirmations?.length || 0}
                        offset={[10, -5]}
                      >
                        <span>Aguardando finalização</span>
                      </Badge>
                    ),
                    key: 'awaiting-confirmation',
                  },
                  {
                    tab: (
                      <Badge
                        count={eventsAvaliations?.length || 0}
                        offset={[10, -5]}
                      >
                        <span>Avaliações pendentes</span>
                      </Badge>
                    ),
                    key: 'pending-avaliations',
                  },
                ]}
                defaultActiveTabKey={'day'}
                onTabChange={(key) => {
                  setCurrentTab(key)
                }}
                size={'small'}
                bordered={false}
                style={{ minHeight: '100%' }}
                bodyStyle={{ pminHeight: '100%' }}
              // extra={<Button type={'link'}>Ver todas</Button>}
              >
                <Table
                  size={'small'}
                  loading={currentDataTableLoading}
                  dataSource={currentDataTable}
                  columns={[
                    {
                      title: 'Data',
                      dataIndex: 'date',
                      key: 'date',
                      render: (date) => (MaskDate(date) ? MaskDate(date) : '-'),
                    },
                    {
                      title: 'Modalidade',
                      dataIndex: ['modality', 'name'],
                      key: 'modality',
                    },
                    {
                      title: 'Sala',
                      dataIndex: 'room',
                      key: 'room',
                      render: (room) => {
                        return (
                          <Tag color={room?.color} key={room?.name}>
                            {room?.name}
                          </Tag>
                        )
                      },
                    },

                    {
                      title: 'Status',
                      dataIndex: 'status',
                      key: 'status',
                      render: (status) => {
                        return (
                          <Tag color={status?.color} key={status?.name}>
                            {status?.name}
                          </Tag>
                        )
                      },
                    },
                    {
                      title: 'Aluno',
                      dataIndex: 'students',
                      key: 'students',
                      render: (students) => {
                        return students?.map((student) => student?.name)
                      },
                    },
                    {
                      title: 'Inicio',
                      dataIndex: 'int_time',
                      key: 'int_time',
                      render: (int_time) => int_time,
                    },
                    {
                      title: 'Ações',
                      key: 'actions',
                      render: (item, _) => (
                        <Space size="middle">
                          <Button
                            type="link"
                            onClick={() => {
                              navigate(`/portal/events/${_.id}`)
                            }}
                          >
                            Ver
                          </Button>
                        </Space>
                      ),
                    },
                  ]}
                  rowKey={'id'}
                />
              </Card>
            </Col>

            <Col xs={24} sm={24} md={24} xl={24}>
              <Card
                title={'Agenda'}
                size={'small'}
                bordered={false}
                style={{ height: '100%' }}
                bodyStyle={{ padding: 0 }}
              >
                <Next5DaysCalendar
                  id={user?.teacher_id}
                  as={'teachers'}
                  currentDate={currentDate}
                />
              </Card>
            </Col>
          </Row>
        </Col>
        <Col
          sm={24}
          md={6}
          xl={6}
          style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
        >
          {/* <Card */}
          {/*  size={'small'} */}
          {/*  style={{ width: '100%' }} */}
          {/*  bodyStyle={{ */}
          {/*    display: 'flex', */}
          {/*    flexDirection: 'column', */}
          {/*    alignItems: 'center', */}
          {/*  }} */}
          {/*  loading={isLoading} */}
          {/*  bordered={false} */}
          {/* > */}
          {/*  <PictureEditor user={user} size={128} /> */}

          {/*  <Title level={3} style={{ marginBottom: 4 }}> */}
          {/*    {user?.name} */}
          {/*  </Title> */}
          {/*  <Text>{user?.username}</Text> */}

          {/*  <Button */}
          {/*    type={'primary'} */}
          {/*    style={{ marginTop: 12 }} */}
          {/*    icon={<MdEdit />} */}
          {/*    disabled */}
          {/*  > */}
          {/*    Editar perfil */}
          {/*  </Button> */}
          {/*  <Divider /> */}
          {/*  <Space direction={'horizontal'}> */}
          {/*    {user?.subjects?.map((subject) => ( */}
          {/*      <Tag key={subject.id} color={subject.color}> */}
          {/*        {subject.name} */}
          {/*      </Tag> */}
          {/*    ))} */}
          {/*  </Space> */}
          {/*  <Divider /> */}
          {/* </Card> */}

          <Calendar
            fullscreen={false}
            headerRender={({ value, type, onChange, onTypeChange }) => {
              // dayjs date to new Date
              const date = new Date(value.format('YYYY-MM-DD'))

              const month = new Intl.DateTimeFormat('pt-BR', {
                month: 'long',
              }).format(date)

              // capitalize first letter
              const monthCapitalized =
                month.charAt(0).toUpperCase() + month.slice(1)

              return (
                <Row
                  justify={'space-between'}
                  align={'middle'}
                  style={{ padding: 8 }}
                >
                  <Col style={{ display: 'flex', alignContent: 'center' }}>
                    <Title level={5} style={{ margin: 0 }}>
                      {monthCapitalized}
                    </Title>
                  </Col>

                  <Col style={{ display: 'flex', gap: 8 }}>
                    <Button
                      onClick={() => {
                        onChange(value.clone().add(-1, 'month'))
                      }}
                    >
                      <MdChevronLeft size={20} />
                    </Button>
                    <Button
                      onClick={() => {
                        onChange(value.clone().add(1, 'month'))
                      }}
                    >
                      <MdChevronRight size={20} />
                    </Button>
                  </Col>
                </Row>
              )
            }}
            onChange={(date) => setCurrentDate(date)}
            dateCellRender={(date) => {
              const isDate = datesEvents?.includes(date.format('YYYY-MM-DD'))

              return isDate ? (
                <div
                  style={{
                    width: 5,
                    height: 5,
                    borderRadius: 4,
                    backgroundColor: '#ff3b18',
                    position: 'absolute',
                    top: 0,
                    right: 0,
                  }}
                />
              ) : null
            }}
            value={dayjs(currentDate)}
          // value={currentDate}
          />
        </Col>
      </Row>
    </Space>
  )
}

export default DashTeachers
