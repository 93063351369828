import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";
import Layout from "../components/Layout";
import { Card, Result } from "antd";
import _ from "lodash";

export const PrivateRoute = () => {
  const { keycloak } = useKeycloak();

  const isAuthenticated = keycloak?.authenticated;

  useEffect(() => {
    // Redirect to login directly, remove to create home page
    if (!isAuthenticated) keycloak.login();
  }, [keycloak, isAuthenticated]);

  return (
    <>
      {isAuthenticated && (
        <Layout>
          <Outlet />
        </Layout>
      )}
    </>
  );
};

interface IValidateRoute {
  have: string[];
  is?: string[];
  children: React.ReactNode;
}

export const ValidateRoute = ({ have, is, children }: IValidateRoute) => {
  const { keycloak } = useKeycloak();

  const permissions = keycloak?.resourceAccess?.contemporaneo?.roles;

  const hasPermission = _.intersection(have, permissions).length > 0;

  const hasProfile = is
    ? keycloak?.tokenParsed?.profiles?.some((profile) => is.includes(profile))
    : true;

  if (is) {
    if (!hasProfile) {
      return (
        <Card>
          <Result
            status="403"
            title="403"
            subTitle={`Você não tem permissão para acessar esta página. Contate o administrador do sistema para obter mais informações.`}
          />
        </Card>
      );
    }
  }

  return (
    <>
      {hasPermission ? (
        children
      ) : (
        <Card>
          <Result
            status="403"
            title="403"
            subTitle={`Você não tem permissão para acessar esta página. Contate o administrador do sistema para obter mais informações.`}
          />
        </Card>
      )}
    </>
  );
};
