import { useQuery } from '@tanstack/react-query'
import {
  Button,
  Col,
  Divider,
  Form,
  notification,
  Select,
  Typography,
} from 'antd'
import React, { useEffect } from 'react'
import api from '~/services/axios'
import useContextStore from '~/stores/useContextStore'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { queryClient } from '~/services/queryClient'

interface ProfileProps {
  id: string
}

const Profile = ({ id }: ProfileProps) => {
  const [form] = Form.useForm()
  const { currentProfile, setCurrentProfile } = useContextStore()

  const { Text, Title } = Typography

  const { data: student, isLoading: isLoadingStudent } = useQuery(
    ['student', id],
    () =>
      api.get(`/api/students/${id}`).then((res) => {
        return res.data
      }),
    {
      // enabled: currentProfile === 'admin',
    },
  )

  const { data: grades, isLoading: isLoadingGrades } = useQuery(
    ['grades'],
    () =>
      api.get(`/api/grades`).then((res) => {
        // remove the 'Plantão', 'Tutoria' and 'Grupo' from grade
        return res.data.filter((grade) => {
          return (
            grade.type !== 'Plantão' &&
            grade.type !== 'Tutoria' &&
            grade.type !== 'Grupo 2h30'
          )
        })
      }),
    {
      // enabled: currentProfile === 'admin',
    },
  )

  useEffect(() => {
    if (student) {
      const { name, email, phone, address, grades, ...rest } = student

      form.setFieldsValue({
        grades: grades.map((grade) => {
          return {
            year: grade.year,
            type: grade.type,
          }
        }),
      })
    }
  }, [student])

  const onFinish = () => {
    api
      .post(`/api/students/${id}/grades`, {
        grades: form.getFieldsValue().grades,
      })
      .then((res) => {
        notification.success({
          message: 'Histórico atualizado com sucesso!',
          description: 'As informações foram atualizadas com sucesso',
        })

        queryClient.invalidateQueries(['student', id])
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <Col span={24} style={{ maxWidth: '990px', margin: '0 auto' }}>
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        // initialValues={{ requiredMarkValue: requiredMark }}
        // onValuesChange={onRequiredTypeChange}
        requiredMark={true}
      >
        {/*  History */}
        <Divider orientation="left">Histórico</Divider>

        <Form.List name="grades">
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <div key={field.key} style={{ display: 'flex', width: '100%' }}>
                  <Form.Item
                    validateTrigger={['onChange', 'onBlur', 'onClick']}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, insira o ano',
                      },
                    ]}
                    name={[index, 'year']}
                    // noStyle
                    style={{
                      width: fields.length > 1 ? 'calc(49% - 16px)' : '49%',
                      marginRight: '2%',
                    }}
                  >
                    <Select
                      placeholder="Ano letivo"
                      options={new Array(20).fill(0).map((_, i) => {
                        return {
                          label: `${new Date().getFullYear() - (i - 1)}`,
                          value: Number(
                            `${new Date().getFullYear() - (i - 1)}`,
                          ),
                        }
                      })}
                    />
                  </Form.Item>
                  <Form.Item
                    validateTrigger={['onChange', 'onBlur']}
                    rules={[
                      {
                        required: true,
                        message: 'Por favor, selecione uma turma',
                      },
                    ]}
                    name={[index, 'type']}
                    // noStyle
                    style={{
                      width: fields.length > 1 ? 'calc(49% - 16px)' : '49%',
                    }}
                  >
                    <Select
                      placeholder="Ano letivo"
                      options={grades?.map((grade) => {
                        return {
                          label: grade.type,
                          value: grade.type,
                        }
                      })}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      style={{ margin: '0 8px', marginBottom: '24px' }}
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </div>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: '100%' }}
                  icon={<PlusOutlined />}
                >
                  Adicionar novo ano
                </Button>
                <Form.ErrorList errors={errors} />
              </Form.Item>
            </>
          )}
        </Form.List>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            // onClick={() => form.submit()}
          >
            Atualizar histórico
          </Button>
        </Form.Item>
      </Form>
    </Col>
  )
}

export default Profile
